import { Dialog as HexaDialog } from "@hexa-ui/components";
import React from "react";
import styled from "styled-components";
import { DialogProps } from "./types";

const DialogWrapper = styled.div`
  [role="dialog"] {
    top: 50%;
    left: 50%;
    bottom: auto;
    min-width: max-content;
  }
`;

export const Dialog = ({
  children,
  ...dialogProps
}: DialogProps): JSX.Element => (
  <DialogWrapper>
    <HexaDialog.Root {...dialogProps}>{children}</HexaDialog.Root>
  </DialogWrapper>
);

import React from "react";
import { Container } from "./styles";

interface Props {
  onClick: () => void;
}

export function BackLink({ onClick }: Props): JSX.Element {
  return (
    <Container onClick={onClick} data-testid="backLink">
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>6CDDD753-5B40-4A87-A288-9CCA0BDEE208</title>
        <defs>
          <path
            d="M9.79549513,0.329504871 C10.2010396,0.735049328 10.2322353,1.37318427 9.88908231,1.81451187 L9.79549513,1.92049513 L3.842,7.874 L16.875,7.875 C17.4963203,7.875 18,8.37867966 18,9 C18,9.57694032 17.5657048,10.0524456 17.0061988,10.1174313 L16.875,10.125 L3.84,10.124 L9.79549513,16.0795049 C10.2010396,16.4850493 10.2322353,17.1231843 9.88908231,17.5645119 L9.79549513,17.6704951 C9.38995067,18.0760396 8.75181573,18.1072353 8.31048813,17.7640823 L8.20450487,17.6704951 L0.329504871,9.79549513 L0.312801266,9.77843546 C0.294876196,9.75973787 0.277590459,9.74042253 0.260979186,9.72052458 L0.329504871,9.79549513 C0.293209842,9.7592001 0.259913242,9.72104209 0.22961507,9.6813289 C0.218322089,9.6663822 0.207518604,9.65145509 0.197078735,9.63625869 C0.183400793,9.61652939 0.170455757,9.59632646 0.158220357,9.57581801 C0.149907124,9.56171278 0.141662858,9.54718544 0.133737076,9.53246126 C0.12271796,9.51212042 0.112537937,9.49168767 0.103017756,9.47102568 C0.0952870665,9.45419022 0.088008765,9.43734436 0.081136215,9.42029139 C0.0732086095,9.4005483 0.0658776457,9.38069599 0.0591250803,9.36069144 C0.0207680186,9.24747763 0,9.12616413 0,9 C0,8.83315942 0.0363183937,8.67480146 0.101480555,8.53240074 C0.108393014,8.51718598 0.11572889,8.5020318 0.123424651,8.48701446 C0.134104287,8.46635813 0.14524555,8.44621522 0.156974225,8.426462 C0.165602536,8.41173768 0.174657819,8.39724371 0.184074391,8.38291752 C0.197264636,8.36303568 0.210818443,8.34376886 0.224948134,8.32495936 C0.22842107,8.3202114 0.232146777,8.31533788 0.235917689,8.31048813 L0.26096225,8.27949571 C0.276662683,8.26068792 0.292965687,8.24240062 0.309841596,8.22466348 L0.329504871,8.20450487 L8.20450487,0.329504871 C8.6438447,-0.109834957 9.3561553,-0.109834957 9.79549513,0.329504871 Z"
            id="path-1"
          />
        </defs>
        <g
          id="✓-Users"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Users.-User-details.-Customer"
            transform="translate(-139.000000, -99.000000)"
          >
            <g
              id="Buttons/03_TertiaryIcon/Medium/Default"
              transform="translate(128.000000, 88.000000)"
            >
              <g
                id="ZZ_Color-Override/01_Primary/BEESBlack"
                transform="translate(11.000000, 11.000000)"
              >
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <use
                  id="Mask"
                  fill="#000000"
                  fillRule="nonzero"
                  xlinkHref="#path-1"
                />
                <g
                  id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
                  mask="url(#mask-2)"
                  fill="#000000"
                  fillOpacity="0.9"
                >
                  <g transform="translate(-3.000000, -3.000000)" id="Rectangle">
                    <rect x="0" y="0" width="24" height="24" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Container>
  );
}

import { styled } from "@bees/vision-tokens"
import {
  Heading as HexaUI,
  Paragraph as HexaParagraph,
} from "@hexa-ui/components";


export const ContainerActions = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "$4",
});

export const Heading = styled(HexaUI, {
  "&&": {
    marginBottom: "$4",
  }
});

export const Paragraph = styled(HexaParagraph, {
  "&&": {
    marginBottom: "$8",
  }
});

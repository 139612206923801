import { DialogProvider } from "components/Dialog";
import { TabProvider } from "hooks/useTab";
import { PocPage } from "pages/Pocs/PocPage";
import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Spinner } from "supplier-portal-shared-components";
import { PocDetailsPage } from "./pages/PocDetails/PocDetails";

const AccountManagement = lazy(
  () => import("identity-admin-mfe/modules/account-management")
);

export const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
        <DialogProvider>
          <Switch>
            <Route exact path="/accounts/old-list">
              <TabProvider>
                <PocPage />
              </TabProvider>
            </Route>
            <Route
              exact
              path="/accounts/account-details-old"
              component={PocDetailsPage}
            />
            <AccountManagement />
          </Switch>
        </DialogProvider>
      </Suspense>
    </BrowserRouter>
  );
};

import SearchIcon from "@material-ui/icons/Search";
import React, { ChangeEvent, FormEvent, InputHTMLAttributes } from "react";
import { Container, StyledInput } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: FormEvent) => void;
}

export function SearchBar({
  value,
  onChange,
  onSubmit,
  ...rest
}: InputProps): JSX.Element {
  return (
    <Container data-testid="search-form" onSubmit={onSubmit}>
      <SearchIcon />
      <StyledInput
        type="search"
        data-testid="search-input"
        onChange={onChange}
        value={value}
        {...rest}
      />
    </Container>
  );
}

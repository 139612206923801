/* eslint-disable import/order */

import * as Styled from "./ExportUserListModal.styles";

import { IExportUserListModalProps, purchaseOptions, userOptions } from ".";
import React, { useCallback, useMemo } from "react";

import { AnalyticService } from "identity-admin-mfe/modules/account-management/services";
import { BffService } from "services/bff-service";
import { Button } from "@hexa-ui/components";
import { CheckboxGroup } from "./CheckboxGroup";
import { Dialog } from "components/Dialog";
import { IFeedback } from "components/Feedbacks/Feedbacks";
import { ProcessingFileModal } from "./ProcessingFileModal";
import axios from "axios";
import { useTab } from "hooks/useTab";
import { useUserMetadata } from "admin-portal-shared-services";

export const ExportUserListModal = ({
  modalProps: {
    isOpenModal,
    setOpenModal,
    vendorId,
    vendorName,
    country,
    feedbackRef,
  },
}: IExportUserListModalProps): JSX.Element => {
  const [isProcessingFile, setIsProcessingFile] = React.useState(false);
  const { setTabName } = useTab();
  const handleCloseModal = useCallback(() => {
    setOpenModal(!isOpenModal);
    setIsProcessingFile(false);
    if (isProcessingFile) setTabName("Processed Files");
  }, [isOpenModal, isProcessingFile, setOpenModal, setTabName]);
  const { data: userData } = useUserMetadata();
  const loggedUser = useMemo(() => userData, [userData]);

  const pushNewFeedback = useCallback(
    (feedback: IFeedback) => {
      feedbackRef.current?.pushNewFeedback(feedback);
    },
    [feedbackRef]
  );

  const feedback = useCallback(
    (message: string, type: "success" | "error") => {
      const newfeedback: IFeedback = {
        message,
        lifeTime: 3000,
        variant: type,
      };
      pushNewFeedback(newfeedback);
    },
    [pushNewFeedback]
  );

  const errorNetworkFeedback = useCallback(
    (errorCode: number) => {
      if (errorCode >= 500 && errorCode <= 509) {
        feedback("Unavailable service. Please try again later.", "error");
        setIsProcessingFile(false);
      }
    },
    [feedback]
  );

  const EXPORT_USERS_FILES_PENDING = "export-users-files-awaiting-feedback";
  const handleExportUsers = useCallback(async () => {
    if (!loggedUser?.userId) return;

    AnalyticService.exportAccountList({
      wholesalerCountry: country,
      wholesalerFilter: vendorName || undefined,
    });

    setIsProcessingFile(true);
    try {
      const bffService = new BffService();
      const fileName = await bffService.createUsersListExport({
        country,
        userId: loggedUser?.userId,
        vendorId,
      });
      const fileListAwaitingFeedback = localStorage.getItem(
        EXPORT_USERS_FILES_PENDING
      );
      if (!fileListAwaitingFeedback) {
        localStorage.setItem(
          EXPORT_USERS_FILES_PENDING,
          JSON.stringify([fileName])
        );
      }

      const filesAwaitingFeedbackParsed: string[] = fileListAwaitingFeedback
        ? JSON.parse(fileListAwaitingFeedback)
        : [];

      filesAwaitingFeedbackParsed.push(fileName);
      localStorage.setItem(
        EXPORT_USERS_FILES_PENDING,
        JSON.stringify(filesAwaitingFeedbackParsed)
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.status) {
        errorNetworkFeedback(error.response.status);
      }
    }
  }, [country, errorNetworkFeedback, loggedUser?.userId, vendorId, vendorName]);
  return (
    <Dialog
      variant="overlay"
      contentCss={{ padding: "$8", margin: "0", width: `648px` }}
      open={isOpenModal}
      onClose={handleCloseModal}
      onInteractOutside={handleCloseModal}
      onEscapeKeyDown={handleCloseModal}
    >
      {isProcessingFile ? (
        <ProcessingFileModal onClose={handleCloseModal} />
      ) : (
        <>
          <Styled.Heading size="H2">Export user list</Styled.Heading>
          <Styled.Paragraph>
            Select the options to export the user list as a CSV file.
          </Styled.Paragraph>

          <CheckboxGroup title="Users" options={userOptions} />
          <CheckboxGroup
            title="Purchase experience"
            options={purchaseOptions}
          />

          <Styled.ContainerActions>
            <Button variant="secondary" size="large" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="primary" size="large" onClick={handleExportUsers}>
              Export
            </Button>
          </Styled.ContainerActions>
        </>
      )}
    </Dialog>
  );
};

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box; 
    }

    body {
        font-family: 'Work sans', sans-serif;
        .container_mfe {
            padding: 32px 32px 32px 114px;
            @media (min-width: 1440px) {
                margin: 0 auto;
            }
        }
    }

    h1, h2, h3, h4,h5,h6 {
        font-family: 'Barlow', sans-serif;
    }

    /* width */
    ::-webkit-scrollbar {
    width: 10px;
    border-radius: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #555;
    }
`;

import { Tabs } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

const { Trigger, List, Root, Content } = Tabs;

export const TabsContainer = styled(Root, {
  "& button[role='tab'][aria-selected='false']": {
    color: "rgba(20, 20, 20, 0.56)",
  },
  "& button[role='tab'][aria-selected='true']": {
    boxShadow:
      "currentcolor 0px -2px 0px 0px inset, currentcolor 0px 2px 0px 0px; ",
  },
  cursor: "pointer",
});

export const TabsList = styled(List, {});

export const TabItem = styled(Trigger, {});

export const TabContent = styled(Content, {
  backgroundColor: "transparent",
  padding: "0",
});

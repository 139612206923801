import {
  AuditLog,
  AuditLogParams,
  LogEntitiesAvailable,
  UpdateAccountLog,
  UpdateAccountSettingsLog,
  UpdateUserLog,
} from "./types";

const tryParseJson = (json: string) => {
  try {
    return JSON.parse(json);
  } catch {
    return json;
  }
};

export const parseAuditLog = (auditLog: AuditLog): AuditLog => {
  const metadata = auditLog.action.metadata as Record<string, string>;
  const parsedMetadata = Object.keys(metadata).reduce(
    (acc, key) => ({
      ...acc,
      [key]: tryParseJson(metadata[key]),
    }),
    {}
  );

  return {
    ...auditLog,
    action: { ...auditLog.action, metadata: parsedMetadata },
  };
};

export const buildAuditLog = ({
  operation,
  metadata,
  entity,
  entityId,
}: AuditLogParams): Pick<AuditLog, "action" | "target"> => {
  const stringifiedMetadata = Object.keys(metadata).reduce(
    (acc, key) => ({
      ...acc,
      [key]:
        typeof metadata[key] !== "string"
          ? JSON.stringify(metadata[key])
          : metadata[key],
    }),
    {}
  );

  return {
    action: { metadata: stringifiedMetadata, operation },
    target: { entity, entityId },
  };
};

const hasValidAccountMetadata = (auditLog: UpdateAccountLog): boolean => {
  const {
    action: { metadata },
  } = auditLog;
  const { actionOwnerName, userId } = metadata;

  return Boolean(actionOwnerName && (userId?.added || userId?.deleted));
};

const hasValidAccountSettingsMetadata = (
  auditLog: UpdateAccountSettingsLog
): boolean => {
  const {
    action: { metadata },
  } = auditLog;
  const {
    actionOwnerName,
    previousPurchaseExperience,
    currentPurchaseExperience,
  } = metadata;

  return Boolean(
    actionOwnerName && previousPurchaseExperience && currentPurchaseExperience
  );
};

const hasValidUserMetadata = (auditLog: UpdateUserLog): boolean => {
  const {
    action: { metadata },
  } = auditLog;
  const { actionOwnerName, accountId } = metadata;

  return Boolean(actionOwnerName && (accountId?.added || accountId?.deleted));
};

export const isUpdateUserLog = (
  auditLog: AuditLog
): auditLog is UpdateUserLog =>
  auditLog.target.entity === LogEntitiesAvailable.Users &&
  hasValidUserMetadata(auditLog as UpdateUserLog);

export const isUpdateAccountLog = (
  auditLog: AuditLog
): auditLog is UpdateAccountLog =>
  auditLog.target.entity === LogEntitiesAvailable.Accounts &&
  hasValidAccountMetadata(auditLog as UpdateAccountLog);

export const isUpdateAccountSettingsLog = (
  auditLog: AuditLog
): auditLog is UpdateAccountSettingsLog =>
  auditLog.target.entity === LogEntitiesAvailable.Accounts &&
  hasValidAccountSettingsMetadata(auditLog as UpdateAccountSettingsLog);

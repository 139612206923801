import { Checkbox } from "@hexa-ui/components";
import React from "react";
import { ICheckboxProps } from "../types";
import * as Styled from "./CheckboxGroup.styles";

export const CheckboxGroup = ({
  title,
  options,
}: ICheckboxProps): JSX.Element => (
  <Styled.Section>
    <Styled.Heading size="H5">{title}</Styled.Heading>
    <Styled.ContainerOptions>
      {options.map((option) => (
        <Checkbox
          key={option.id}
          id={option.id}
          label={option.label}
          value={option.value}
          checked={option.checked}
        />
      ))}
    </Styled.ContainerOptions>
  </Styled.Section>
);
import {
  AccountDetailsViewed,
  AccountListViewed,
  AllUserDeselection,
  AllUserSelection,
  ChangeSettingsSubmissionResult,
  ChangeSettingsSubmitted,
  UserDeselection,
  UserRemovalSubmissionResult,
  UserRemovalSubmitted,
  UserSelection,
  WholesalerFiltered,
} from "analytics";
import {
  PurchaseExperience,
  ScreenName,
  ScreenName2,
  SearchCriteria,
  WholesalerCountry,
  WholesalerFilter,
} from "identity-admin-mfe/modules/account-management/services";
import { IPoc } from "interfaces";
import { AccountListSearched } from "../../../analytics/index";

interface IBaseAccountSegmentData {
  account_id: string | null;
  account_name: string | null;
  associated_users: number;
  change_settings_permission: boolean;
  invite_user_permission: boolean;
  purchase_experience: PurchaseExperience;
  screen_name: ScreenName;
}

export interface IAccountSegmentDataParams {
  accountId?: string;
  name?: string;
  associated_users?: number;
  canChangeAccountSettings?: boolean;
  canInviteUser?: boolean;
  isKeyAccount?: boolean;
  screen_name?: ScreenName | ScreenName2;
}
export interface IAccountWithSelectedsUsersSegmentDataWithParams
  extends IAccountSegmentDataParams {
  selected_users?: number;
}
export interface IAccountWithPurchaseExperienceSegmentDataWithParams
  extends IAccountSegmentDataParams {
  purchase_experience_changed: boolean;
}

export interface IRemovalCountSegmentDataParams {
  remomal_success_count: number;
  removal_fail_count: number;
}
export interface IAccountWithRemoveCountSegmentDataWithParams
  extends IAccountSegmentDataParams,
    IRemovalCountSegmentDataParams {}
export class SegmentFormatData {
  private static selectEnumCountry(value: string | undefined) {
    const country: { [key: string]: WholesalerCountry } = {
      AR: WholesalerCountry.Ar,
      BR: WholesalerCountry.Br,
      CA: WholesalerCountry.Ca,
      CO: WholesalerCountry.Co,
      DR: WholesalerCountry.Dr,
      EC: WholesalerCountry.Ec,
      HN: WholesalerCountry.Hn,
      KR: WholesalerCountry.Kr,
      MX: WholesalerCountry.Mx,
      PA: WholesalerCountry.Pa,
      PE: WholesalerCountry.Pe,
      PY: WholesalerCountry.Py,
      SV: WholesalerCountry.Sv,
      TZ: WholesalerCountry.Tz,
      UG: WholesalerCountry.Ug,
      UK: WholesalerCountry.Uk,
      UY: WholesalerCountry.Uy,
      ZA: WholesalerCountry.Za,
    };
    if (value) return country[value];
    return WholesalerCountry.Br;
  }

  private static validateValueType(value: string): SearchCriteria {
    const regexEmail =
      /^([a-zA-Z0-9+_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const regexId = /[0-9]/;
    const regexName = /^[A-Za-zÀ-ú.\s]+$/;

    if (regexEmail.test(value)) {
      return SearchCriteria.Email;
    }
    if (regexId.test(value)) {
      return SearchCriteria.Id;
    }
    if (regexName.test(value)) {
      return SearchCriteria.Name;
    }
    return SearchCriteria.Other;
  }

  static parseBaseAccountSegmentData(
    accountSegmentDataParams: IAccountSegmentDataParams
  ): IBaseAccountSegmentData {
    return {
      account_id: accountSegmentDataParams.accountId ?? null,
      account_name: accountSegmentDataParams.name ?? null,
      associated_users: accountSegmentDataParams.associated_users || 0,
      change_settings_permission:
        accountSegmentDataParams.canChangeAccountSettings || false,
      invite_user_permission: accountSegmentDataParams.canInviteUser || false,
      purchase_experience: accountSegmentDataParams.isKeyAccount
        ? PurchaseExperience.BeesLink
        : PurchaseExperience.BeesCustomer,
      screen_name: "ACCOUNT_DETAILS" as ScreenName,
    };
  }

  static formatAccountListSegmentData(
    accounts: IPoc[],
    searchValue: string
  ): AccountListViewed {
    return {
      account_information: accounts.map((poc: IPoc) => {
        return {
          account_id: poc.accountId,
          account_name: poc.name,
          purchase_experience: poc.isKeyAccount
            ? PurchaseExperience.BeesLink
            : PurchaseExperience.BeesCustomer,
          user_associated: poc.usersCount as number,
        };
      }),
      screen_name: ScreenName.Accounts,
      search_criteria: this.validateValueType(searchValue),
      wholesaler_filter: WholesalerFilter.Wholesaler,
    };
  }

  static formatAccountListSearchedSegmentData(
    searchValue: string
  ): AccountListSearched {
    return {
      screen_name: ScreenName.Accounts,
      search_criteria: this.validateValueType(searchValue),
    };
  }

  static formatAccountDetailsViewed(
    accountSegmentDataParams: IAccountSegmentDataParams
  ): AccountDetailsViewed {
    const formatedAccountDetailsViewed: AccountDetailsViewed =
      SegmentFormatData.parseBaseAccountSegmentData(accountSegmentDataParams);
    return formatedAccountDetailsViewed;
  }

  static formatUserRemovalSubmitted(
    accountSegmentDataParams: IAccountSegmentDataParams
  ): UserRemovalSubmitted {
    const formatedChangeSettingsSubmitted: UserRemovalSubmitted = {
      ...SegmentFormatData.parseBaseAccountSegmentData(
        accountSegmentDataParams
      ),
      screen_name: ScreenName2.AccountDetails,
    };
    return formatedChangeSettingsSubmitted;
  }

  static formatUserRemovalSubmissionResult(
    accountSegmentDataParams: IAccountWithRemoveCountSegmentDataWithParams
  ): UserRemovalSubmissionResult {
    const { remomal_success_count, removal_fail_count } =
      accountSegmentDataParams;
    const formatedUserRemovalSubmissionResult: UserRemovalSubmissionResult = {
      ...SegmentFormatData.parseBaseAccountSegmentData(
        accountSegmentDataParams
      ),
      remomal_success_count,
      removal_fail_count,
      screen_name: ScreenName2.AccountDetails,
    };
    return formatedUserRemovalSubmissionResult;
  }

  static formatChangeSettingsSubmitted(
    accountSegmentDataParams: IAccountSegmentDataParams
  ): ChangeSettingsSubmitted {
    const formatedChangeSettingsSubmitted: ChangeSettingsSubmitted =
      SegmentFormatData.parseBaseAccountSegmentData(accountSegmentDataParams);
    return formatedChangeSettingsSubmitted;
  }

  static formatChangeSettingsSubmissionResult(
    accountSegmentDataParams: IAccountWithPurchaseExperienceSegmentDataWithParams
  ): ChangeSettingsSubmissionResult {
    const { purchase_experience_changed } = accountSegmentDataParams;
    const formatedChangeSettingsSubmissionResult: ChangeSettingsSubmissionResult =
      {
        ...SegmentFormatData.parseBaseAccountSegmentData(
          accountSegmentDataParams
        ),
        purchase_experience_changed,
      };
    return formatedChangeSettingsSubmissionResult;
  }

  static formatUserSelection(
    accountSegmentDataParams: IAccountWithSelectedsUsersSegmentDataWithParams
  ): UserSelection {
    const { selected_users } = accountSegmentDataParams;
    const formatedUserSelection: UserSelection = {
      ...SegmentFormatData.parseBaseAccountSegmentData(
        accountSegmentDataParams
      ),
      selected_users: selected_users || 0,
      screen_name: ScreenName2.AccountDetails,
    };
    return formatedUserSelection;
  }

  static formatUserDeselection(
    accountSegmentDataParams: IAccountWithSelectedsUsersSegmentDataWithParams
  ): UserDeselection {
    const { selected_users } = accountSegmentDataParams;
    const formatedUserDeselection: UserSelection = {
      ...SegmentFormatData.parseBaseAccountSegmentData(
        accountSegmentDataParams
      ),
      selected_users: selected_users || 0,
      screen_name: ScreenName2.AccountDetails,
    };
    return formatedUserDeselection;
  }

  static formatAllUserSelection(
    accountSegmentDataParams: IAccountSegmentDataParams
  ): AllUserSelection {
    const formatedAllUserSelection: AllUserSelection = {
      ...SegmentFormatData.parseBaseAccountSegmentData(
        accountSegmentDataParams
      ),
      screen_name: ScreenName2.AccountDetails,
    };
    return formatedAllUserSelection;
  }

  static formatAllUserDeselection(
    accountSegmentDataParams: IAccountSegmentDataParams
  ): AllUserDeselection {
    const formatedAllUserDeselection: AllUserDeselection = {
      ...SegmentFormatData.parseBaseAccountSegmentData(
        accountSegmentDataParams
      ),
      screen_name: ScreenName2.AccountDetails,
    };
    return formatedAllUserDeselection;
  }

  static getRemovalCount(
    results: PromiseSettledResult<any>[]
  ): IRemovalCountSegmentDataParams {
    const remomal_success_count = results.filter(
      (promise) => promise.status === "fulfilled"
    ).length;
    const removal_fail_count = results.filter(
      (promise) => promise.status === "rejected"
    ).length;
    return { remomal_success_count, removal_fail_count };
  }

  static formatWholesalerFilteredData(country: string) {
    const formatedWholesalerFilteredData: WholesalerFiltered = {
      screen_name: ScreenName.Accounts,
      wholesaler_country: this.selectEnumCountry(country),
      wholesaler_filter: WholesalerFilter.Wholesaler,
    };
    return formatedWholesalerFilteredData;
  }
}

import { useAuthenticationService as authService } from "admin-portal-shared-services";
import { getToken } from "helpers";
import { axiosInstance } from "services/axiosInstance";
import { getApiHost } from "services/host/HostService";
import { v4 as uuidv4 } from "uuid";

type IGetUsersParams = {
  vendorCountry: string;
  accountId: string;
  vendorAccountId: string;
  vendorId: string;
};

export class UserService {
  URL = `${getApiHost()}`;

  async getUsers({ vendorCountry, ...params }: IGetUsersParams): Promise<any> {
    const headers = {
      country: vendorCountry,
      requestTraceId: uuidv4(),
      Authorization: getToken(),
    };

    const url = `${this.URL}/poc-mfe/accounts/users`;

    const { data } = await authService()
      .enhancedAxios(axiosInstance)
      .get(url, { params, headers });

    return data;
  }

  async disassociateUser(
    vendorCountry: string,
    payload: { userId: string; value: string }
  ): Promise<Array<any>> {
    const headers = {
      country: vendorCountry,
      requestTraceId: uuidv4(),
      Authorization: getToken(),
    };

    const url = `${this.URL}/users/disassociate`;

    try {
      const { data } = await authService()
        .enhancedAxios(axiosInstance)
        .delete(url, { headers, data: [payload] });
      return data;
    } catch (err: any) {
      const errorMessage = `Error(#${err?.response?.status}): ${err?.response?.data?.error?.description}`;
      throw new Error(errorMessage);
    }
  }

  async getVendorsOfUser(): Promise<any> {
    const headers = {
      requestTraceId: uuidv4(),
      Authorization: getToken(),
    };

    const url = `${this.URL}/users/vendors/`;

    try {
      const { data } = await authService()
        .enhancedAxios(axiosInstance)
        .get(url, { headers });
      return data;
    } catch (err: any) {
      throw new Error(err.message);
    }
  }
}

import { AuditLogsItemTypes } from "../base";

export const BINDING_OPTIONS = {
  ADD: "added",
  DELETE: "deleted",
} as const;

export const BINDING_OPTION_TO_ITEM = {
  [BINDING_OPTIONS.ADD]: AuditLogsItemTypes.Addition,
  [BINDING_OPTIONS.DELETE]: AuditLogsItemTypes.Removal,
} as const;

import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const StyledContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 308px 1fr;
`;

export const Container = styled.div``;

export const StyledTitle = styled(Typography)`
  padding: 24px 24px 16px 24px;
  font: 600 24px/32px "Barlow";
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 130px;
`;

export const ContainerHeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

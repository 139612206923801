import { styled } from "@bees/vision-tokens";
import { Button } from "@hexa-ui/components";
import React, { ReactNode } from "react";

export interface DialogAction {
  label: string;
  callback?: () => void;
}

export enum DialogFooterSizes {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export interface DialogFooterProps {
  primaryAction?: DialogAction;
  secondaryAction?: DialogAction;
  children?: ReactNode;
  size?: DialogFooterSizes;
}

export const Footer = styled("div", {
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "flex-end",
  width: "100%",
  "&> *:not(:last-child)": {
    marginRight: "$4",
  },
});

export const DialogFooter = ({
  primaryAction,
  secondaryAction,
  children,
  size = DialogFooterSizes.Medium,
}: DialogFooterProps) => {
  if (!children && !primaryAction && !secondaryAction) return null;

  if (children) return <Footer>{children}</Footer>;

  return (
    <Footer>
      {primaryAction && (
        <Button
          key="primary"
          size={size}
          onClick={primaryAction.callback}
          variant="primary"
        >
          {primaryAction.label}
        </Button>
      )}
      {secondaryAction && (
        <Button
          key="secondary"
          size={size}
          onClick={secondaryAction.callback}
          variant="primary"
        >
          {secondaryAction.label}
        </Button>
      )}
    </Footer>
  );
};

import styled from "styled-components";

export const Container = styled.section`
  margin-top: 24px;
  padding-left: 32px;
  padding-right: 32px;
`;

export const HeaderContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;

  & h2 {
    color: rgba(0, 0, 0, 0.9);
    font: 600 20px/24px "Barlow", sans-serif;
  }

  & button {
    border: 1px solid #000;
    border-radius: 16px;
    background-color: #fff;
    cursor: pointer;
    color: #000;
    font: 500 14px/16px "Work Sans", sans-serif;
    height: 32px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
  }
`;

export const ContentInfo = styled.div`
  margin-top: 8px;

  & h4 {
    color: rgba(0, 0, 0, 0.55);
    font: 400 14px/20px "Work Sans", sans-serif;
  }

  & span {
    color: rgba(0, 0, 0, 0.9);
    font: 400 16px/24px "Work Sans", sans-serif;
  }
`;

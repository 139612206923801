import {
  EmptyState,
  SegmentedControl,
  SegmentedControlOptions,
} from "@hexa-ui/components";
import { useEnvService } from "admin-portal-shared-services";
import { TabName } from "identity-admin-mfe/modules/account-management/services";
import { Env } from "interfaces/IEnv";
import React, { useEffect, useState } from "react";
import {
  auditLogsService,
  isUpdateAccountSettingsLog,
  UpdateAccountLog,
  UpdateAccountSettingsLog,
} from "services";
import { ApprovalFlowService } from "services/approvalFlow/ApprovalFlowService";
import {
  AprovalRequestStatusEnum,
  IApprovalFlowRequesters,
} from "services/approvalFlow/types";
import { SegmentService } from "services/segmentService/segmentService";
import {
  AuditLogsListContainer,
  CenteringEmptyState,
} from "./AuditLogsList.styles";
import {
  AuditLogsItem,
  AuditLogsItemTypes,
  UpdateAccountItem,
  UpdateAccountSettingsItem,
} from "./item";
import { ActionOwnerInfo } from "./item/actionOwnerInfo";
import { AuditLogsListLoader } from "./loader";

type AccountLogHistory =
  | UpdateAccountLog[]
  | UpdateAccountSettingsLog[]
  | undefined;

const getTabsByEnv = (isProd: boolean): SegmentedControlOptions[] => {
  const tabs: SegmentedControlOptions[] = [
    {
      label: "Completed",
      value: "completed",
    },
  ];
  if (isProd) {
    tabs.unshift({
      label: "Pending",
      value: "pending",
    });
  }
  return tabs;
};

export const AuditLogsList = ({
  targetEntityId,
}: {
  targetEntityId: string;
}): JSX.Element => {
  const [logHistory, setLogHistory] = useState<AccountLogHistory>([]);
  const [approvalFlowRequesterList, setApprovalFlowRequesterList] = useState<
    IApprovalFlowRequesters[]
  >([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [tabSelected, setTabSelected] = useState<
    "completed" | "pending" | string
  >("completed");
  const envService = useEnvService();
  const isProdEnvironment = envService.getEnv() === Env.PROD;
  const tabNameSelected = {
    completed: TabName.Completed,
    pending: TabName.Pending,
  };

  // @TODO - Enable in the sprint 120

  /* const isAuditLogApprovalBySystem = logHistory?.some(
    (log) => log.action.metadata.wasApprovedBySystem === true
  ); */

  const isApprovalFlowCompletedStatusLog = approvalFlowRequesterList?.filter(
    (log) => log.status === AprovalRequestStatusEnum.COMPLETED
  );

  const isApprovalFlowPendingStatus = approvalFlowRequesterList?.filter(
    (log) =>
      log.status === AprovalRequestStatusEnum.PENDING ||
      log.status === AprovalRequestStatusEnum.PROCESSING
  );

  const handleGetApprovalRequesters = async () => {
    const approvalRequesters = new ApprovalFlowService();

    const requesters = await approvalRequesters.getRequesters(
      targetEntityId as string
    );

    return setApprovalFlowRequesterList(requesters);
  };

  useEffect(() => {
    const getEntityLogs = async () => {
      setLoading(true);
      const logs = await auditLogsService.getAuditLogHistory(targetEntityId);
      setLogHistory(logs);
      setLoading(false);
    };

    getEntityLogs();

    if (isProdEnvironment) handleGetApprovalRequesters();
  }, [targetEntityId, isProdEnvironment]);

  return (
    <AuditLogsListContainer>
      <SegmentedControl
        options={getTabsByEnv(isProdEnvironment)}
        defaultValue={tabSelected}
        onValueChange={(value) => {
          setTabSelected(value);
          const tabNameKey = value as keyof typeof tabNameSelected;
          SegmentService.recentActivitiesTabSelected(
            tabNameSelected[tabNameKey]
          );
        }}
        css={{ marginBottom: "1rem" }}
      />
      {tabSelected === "completed" && (
        <>
          {isLoading && <AuditLogsListLoader />}

          {!isLoading && (
            <>
              {/* Enable the conditional in the sprint 120 (logHistory?.length === 0 || !isAuditLogApprovalBySystem) && ... */}
              {logHistory?.length === 0 &&
                !isApprovalFlowCompletedStatusLog?.length && (
                  <EmptyState.SectionLevel
                    style={{ width: "100%", margin: "auto" }}
                    description="No recent activity information"
                  />
                )}
              {logHistory === undefined && (
                <EmptyState.SectionLevel description="Unable to retrieve recent activity information, please try again later" />
              )}
              {logHistory?.map((auditLog, i) =>
                isUpdateAccountSettingsLog(auditLog) ? (
                  <UpdateAccountSettingsItem
                    key={i}
                    updateAccountSettingsLog={auditLog}
                  />
                ) : (
                  <UpdateAccountItem key={i} updateAccountLog={auditLog} />
                )
              )}

              {isApprovalFlowCompletedStatusLog?.map((log) => (
                <AuditLogsItem
                  key={log.id}
                  type={log.operation as unknown as AuditLogsItemTypes}
                  header="Updated route ID:"
                  body="#1820385894 → #3849255894"
                  footer={
                    <>
                      <ActionOwnerInfo
                        creationDate={log.createdAt}
                        actionOwnerName={`Requested by ${log.user.name}`}
                        actionOwnerId={log.user.id}
                      />

                      <ActionOwnerInfo
                        creationDate={log.updatedAt}
                        actionOwnerName={`Approved by ${log.replier}`}
                        actionOwnerId={log.user.id}
                      />
                    </>
                  }
                />
              ))}
            </>
          )}
        </>
      )}

      {tabSelected === "pending" &&
        isProdEnvironment &&
        !isApprovalFlowPendingStatus?.length && (
          <CenteringEmptyState>
            <EmptyState.SectionLevel description="No recent activity information" />
          </CenteringEmptyState>
        )}

      {tabSelected === "pending" &&
        isProdEnvironment &&
        isApprovalFlowPendingStatus?.length && (
          <>
            {isLoading && <AuditLogsListLoader />}

            {!isLoading &&
              isApprovalFlowPendingStatus?.map((log) => (
                <AuditLogsItem
                  key={log.id}
                  type={log.operation as unknown as AuditLogsItemTypes}
                  header="Updated route ID:"
                  body="#1820385894 → #3849255894"
                  footer={
                    <ActionOwnerInfo
                      creationDate={log.createdAt}
                      actionOwnerName={`Requested by ${log.user.name}`}
                      actionOwnerId={log.user.id}
                    />
                  }
                />
              ))}
          </>
        )}
    </AuditLogsListContainer>
  );
};

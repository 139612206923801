import Avatar from "@material-ui/core/Avatar";
import styled from "styled-components";

interface IProps {
  size: string;
  fontSize: string;
}

export const StyledAvatar = styled(Avatar)<IProps>`
  font-weight: 600;
  margin: 0;
  && {
    background-color: #f0ecfc;
    color: #212121;
    width: ${(props) => (props.size ? props.size : "32px")};
    height: ${(props) => (props.size ? props.size : "32px")};
    font-family: "Barlow", sans-serif;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "10px")};
  }
`;

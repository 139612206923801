import styled from "styled-components";

interface IProps {
  accountEnabled: boolean;
}

export const StyledStatus = styled.div<IProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => (props.accountEnabled ? "#267a78" : "#c9201e")};
`;

export const StyledStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledTableContainerHeader = styled.div`
  width: 100%;
  min-height: 72px;
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding: 16px;
  color: rgba(0, 0, 0, 0.55);
  font-family: "Work Sans";
  font-size: 14px;
  align-items: center;
`;

export const StyledMessageNoAccounts = styled.span`
  margin: auto;
`;

export const StyledPocContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

import styled from "styled-components";

export const Container = styled.form`
  background-color: #f2f2f2;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 400px;
  padding-left: 12px;
`;

export const StyledInput = styled.input`
  outline: none;
  border: 0;
  background-color: #f2f2f2;
  height: 40px;
  width: 100%;
  border-radius: 20px;
  padding: 0 12px;
  font-size: 14px;

  &::placeholder {
    color: #707372;
    font: 400 14px/16px "Work Sans";
    letter-spacing: normal;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-left: 0.4em;
    background: rgba(0, 0, 0, 0.05);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgba(0,0,0,0.9)'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
    cursor: pointer;
  }
`;

export const Panel = styled.div`
  border-radius: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  min-width: 364px;
  max-width: 632px;
  height: fit-content;
`;

import { IVendor } from "interfaces/IWholesaler";
import React from "react";
import { VendorsFilter } from "./Filters/VendorsFilter";
import * as Styled from "./styles";

interface Props {
  selectedVendorId: string;
  vendors: IVendor[];
  isLoading: boolean;
  vendorChange: (vendor: IVendor) => void;
}

export function Filter({
  selectedVendorId,
  vendors,
  isLoading,
  vendorChange,
}: Props): JSX.Element {
  return (
    <Styled.StyledFilterContainer>
      <Styled.StyledFilterContainerHeader>
        <Styled.StyledFilterHeaderTitle>
          Filter by
        </Styled.StyledFilterHeaderTitle>
      </Styled.StyledFilterContainerHeader>
      <Styled.StyledFiltersContainer>
        <VendorsFilter
          selectedVendorId={selectedVendorId}
          vendors={vendors}
          isLoading={isLoading}
          onHandleChange={vendorChange}
        />
      </Styled.StyledFiltersContainer>
    </Styled.StyledFilterContainer>
  );
}

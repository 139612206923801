import styled from "styled-components";

export const List = styled.ol`
  list-style: none;
  margin-top: 16px;
  align-self: flex-end;

  & li {
    display: flex;
    align-items: flex-start;

    font: 400 14px/20px "Work Sans", sans-serif;
    color: rgba(0, 0, 0, 0.55);
    margin-bottom: 4px;

    & svg {
      margin-right: 16px;
    }
  }
`;

import React from "react";
import { StyledContainer } from "./styles";

interface Props {
  isVisible: boolean;
  loadingMessage?: string;
}

export const InfiniteScrollLoading = ({
  isVisible,
  loadingMessage,
}: Props): JSX.Element => {
  return (
    <StyledContainer $isVisible={isVisible}>{loadingMessage}</StyledContainer>
  );
};

InfiniteScrollLoading.defaultProps = {
  loadingMessage: "Loading...",
};

import { useAuthenticationService as authService } from "admin-portal-shared-services";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export interface GetRegistrationLinkParams {
  inviteId: string;
  userId: string;
  userCountry: string;
}

export interface ResendInviteParams {
  userId: string;
  country: string;
  appName: string;
  invitedUserName: string;
  wholesalerName: string;
  accountsName: string[];
  wholesalerId: string;
}

interface GetUsersByAccountParams {
  legacyAccountId: string;
  country: string;
  vendorAccountId: string;
  vendorId: string;
}

interface CreateUserListExportParams {
  country: string;
  vendorId: string;
  userId: string;
}

export class BffService {
  private readonly axiosInstance = authService().enhancedAxios(
    axios.create({
      headers: {
        Authorization: localStorage.getItem("authHeader"),
      },
    })
  );

  private readonly baseUrl: string;

  constructor() {
    this.baseUrl = process.env.API_BASE_URL || "/api/identity-bff/v2";
  }

  async getRegistrationLink(
    params: GetRegistrationLinkParams
  ): Promise<string> {
    const { data } = await this.axiosInstance.get(
      `${this.baseUrl}/account-management/registration-link`,
      {
        headers: {
          requestTraceId: uuidv4(),
        },
        params: {
          inviteId: params.inviteId,
          userId: params.userId,
          userCountry: params.userCountry,
        },
      }
    );

    return data;
  }

  async ResendInvite(params: ResendInviteParams): Promise<string> {
    const { data } = await this.axiosInstance.post(
      `${this.baseUrl}/account-management/resend-customer-invite/${params.userId}`,
      {
        invitedUserName: params.invitedUserName,
        wholesalerName: params.wholesalerName,
        accountsName: params.accountsName,
        wholesalerId: params.wholesalerId,
      },
      {
        headers: {
          requestTraceId: uuidv4(),
        },
        params: {
          country: params.country,
          appName: params.appName,
        },
      }
    );
    return data;
  }

  async getUsersByAccount(params: GetUsersByAccountParams): Promise<any> {
    const { country, legacyAccountId, vendorAccountId, vendorId } = params;
    const { data } = await this.axiosInstance.get(
      `${this.baseUrl}/account-management/users-by-account`,
      {
        headers: {
          requestTraceId: uuidv4(),
        },
        params: {
          country,
          legacyAccountId,
          vendorAccountId,
          vendorId,
        },
      }
    );
    return data;
  }

  async createUsersListExport(
    params: CreateUserListExportParams
  ): Promise<string> {
    const { country, userId, vendorId } = params;
    const { data } = await this.axiosInstance.post(
      `${this.baseUrl}/account-management/export-users-list`,
      {
        userId,
        vendorId,
        country,
      },
      {
        headers: {
          requestTraceId: uuidv4(),
          Authorization: localStorage.getItem("authHeader"),
        },
      }
    );
    return data;
  }
}

export function useBFFService() {
  return new BffService();
}

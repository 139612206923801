import { Checkbox } from "@material-ui/core";
import styled, { css } from "styled-components";

interface Props {
  active?: boolean;
  disabled?: boolean;
}

const changeForAvatar = css`
  & [data-checkbox] {
    display: none;
  }

  & [data-avatar] {
    display: flex;
  }
`;

const changeForCheckbox = css`
  & [data-checkbox] {
    display: flex;
    align-items: center;
    width: 48px;
    height: 48px;
  }

  & [data-avatar] {
    display: none;
  }
`;

const selected = css`
  ${changeForCheckbox}
  background-color: #f0ecfc;

  &:hover {
    background-color: #f0ecfc;
  }
`;

const fieldDisabled = css`
  color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
`;

export const Container = styled.div`
  margin-top: 40px;
`;

export const UserHeader = styled.div`
  margin: 0 32px 8px 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
`;

export const CustomH3 = styled.h3`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;
  margin-bottom: 16px;
`;

export const UserHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectSection = styled.div`
  display: flex;
  align-items: center;
`;

export const UserList = styled.ul`
  list-style: none;
`;

export const UserListItem = styled.li<{ active: boolean }>`
  width: 100%;
  height: 72px;
  display: flex;
  padding: 0 32px;
  background-color: #fff;
  align-items: center;
  justify-content: "space-between";
  transition: background-color 200ms linear;

  ${changeForAvatar}

  & div {
    margin-left: 16px;

    & h4 {
      font: 600 16px/24px "Work sans", sans-serif;
      letter-spacing: 0.1px;
      color: rgba(0, 0, 0, 0.9);
    }

    & p {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: rgba(0, 0, 0, 0.55);
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);

    ${changeForCheckbox}
  }

  ${({ active }) => active && selected}
`;

export const SelectAll = styled.label<Props>`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.9);

  ${({ disabled }) => disabled && fieldDisabled}
`;

export const EmptySpace = styled.div`
  color: rgba(0, 0, 0, 0.55);
  font: 400 14px/20px "Work sans";
  margin: 16px 32px;
`;

export const CustomCheckbox = styled(Checkbox)`
  width: 48px;
  height: 48px;
  line-height: 48px;

  svg {
    fill: #000;
    color: #fff;
  }
`;

export const BlockedHeader = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.55);
  margin: 0 32px;
  p {
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 10px;
  }
`;

export const SelectCounter = styled.p`
  margin-left: 10px;
`;

export const DeselectAll = styled.a`
  margin-left: 10px;
`;

export const UsersCounter = styled.p`
  height: 24px;
  width: 24px;
  background-color: #f0ecfc;
  border-radius: 16px;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.9);
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
`;

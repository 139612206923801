import styled from "styled-components";
import { StyledHeaderTitle } from "components/Shared/styles";

export const StyledScreen = styled.div`
  display: grid;
  grid-template-rows: 56px 112px 1fr;
  height: 100vh;

  > * {
    margin-bottom: 24px;
  }
`;

export const HeaderTitle = styled(StyledHeaderTitle)`
  && {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

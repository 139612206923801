import styled from "styled-components";

export const StyledContainer = styled.div<{ $isVisible: boolean }>`
  width: 100%;
  height: 56px;
  background: #fff;
  display: ${({ $isVisible }) => ($isVisible ? "flex" : "none")};
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid #dfdede;
  padding: 18px 16px;
  border-radius: 0 0 16px 0;
  color: rgba(0, 0, 0, 0.55);
  font: 400 14px/20px "Work Sans";
  letter-spacing: 0.1px;
`;

import segmentAnalytics, {
  AccountDetailsViewed,
  AccountListViewed,
  AllUserDeselection,
  AllUserSelection,
  ChangeSettingsSubmissionResult,
  ChangeSettingsSubmitted,
  UserDeselection,
  UserRemovalSubmissionResult,
  UserRemovalSubmitted,
  UserSelection,
} from "analytics";
import {
  ScreenName,
  ScreenName2,
  TabName,
} from "identity-admin-mfe/modules/account-management/services";
import { IPoc } from "interfaces";
import { AccountListSearched, WholesalerFiltered } from "../../analytics/index";
import {
  IAccountSegmentDataParams,
  IAccountWithPurchaseExperienceSegmentDataWithParams,
  IAccountWithRemoveCountSegmentDataWithParams,
  IAccountWithSelectedsUsersSegmentDataWithParams,
  SegmentFormatData,
} from "./helpers/segmentFormatData";

export class SegmentService {
  static accountDetailsViewed(params: IAccountSegmentDataParams): void {
    const accountDetailsViewedData: AccountDetailsViewed =
      SegmentFormatData.formatAccountDetailsViewed(params);
    segmentAnalytics.accountDetailsViewed(accountDetailsViewedData);
  }

  static addNewUserStarted(): void {
    segmentAnalytics.addNewUserStarted({
      screen_name: ScreenName2.AccountDetails,
    });
  }

  static allUserSelection(
    params: IAccountWithSelectedsUsersSegmentDataWithParams
  ): void {
    const allUserSelectionData: AllUserSelection =
      SegmentFormatData.formatAllUserSelection(params);
    segmentAnalytics.allUserSelection(allUserSelectionData);
  }

  static allUserDeselection(
    params: IAccountWithSelectedsUsersSegmentDataWithParams
  ): void {
    const allUserDeselectionData: AllUserDeselection =
      SegmentFormatData.formatAllUserDeselection(params);
    segmentAnalytics.allUserDeselection(allUserDeselectionData);
  }

  static userSelection(
    params: IAccountWithSelectedsUsersSegmentDataWithParams
  ): void {
    const userSelectionData: UserSelection =
      SegmentFormatData.formatUserSelection(params);
    segmentAnalytics.userSelection(userSelectionData);
  }

  static userDeselection(
    params: IAccountWithSelectedsUsersSegmentDataWithParams
  ): void {
    const userDeselectionData: UserDeselection =
      SegmentFormatData.formatUserDeselection(params);
    segmentAnalytics.userDeselection(userDeselectionData);
  }

  static userRemovalStarted(): void {
    segmentAnalytics.userRemovalStarted({
      screen_name: ScreenName2.AccountDetails,
    });
  }

  static userRemovalCanceled(param: { isClickCancelButton: boolean }): void {
    segmentAnalytics.userRemovalCanceled({
      screen_name: ScreenName2.AccountDetails,
      cancel_button: param.isClickCancelButton,
    });
  }

  static userRemovalSubmitted(params: IAccountSegmentDataParams): void {
    const userRemovalSubmittedData: UserRemovalSubmitted =
      SegmentFormatData.formatUserRemovalSubmitted(params);
    segmentAnalytics.userRemovalSubmitted(userRemovalSubmittedData);
  }

  static userRemovalSubmissionResult(
    params: IAccountWithRemoveCountSegmentDataWithParams
  ): void {
    const userRemovalSubmissionResultData: UserRemovalSubmissionResult =
      SegmentFormatData.formatUserRemovalSubmissionResult(params);
    segmentAnalytics.userRemovalSubmissionResult(
      userRemovalSubmissionResultData
    );
  }

  static changeSettingCanceled(): void {
    segmentAnalytics.changeSettingCanceled({
      screen_name: "ACCOUNT_DETAILS" as ScreenName,
    });
  }

  static changeSettingsSubmitted(params: IAccountSegmentDataParams): void {
    const changeSettingsSubmittedData: ChangeSettingsSubmitted =
      SegmentFormatData.formatChangeSettingsSubmitted(params);
    segmentAnalytics.changeSettingsSubmitted(changeSettingsSubmittedData);
  }

  static changeSettingsSubmissionResult(
    params: IAccountWithPurchaseExperienceSegmentDataWithParams
  ): void {
    const changeSettingsSubmissionResultData: ChangeSettingsSubmissionResult =
      SegmentFormatData.formatChangeSettingsSubmissionResult(params);
    segmentAnalytics.changeSettingsSubmissionResult(
      changeSettingsSubmissionResultData
    );
  }

  static changeSettingStarted(): void {
    segmentAnalytics.changeSettingStarted({
      screen_name: "ACCOUNT_DETAILS" as ScreenName,
    });
  }

  static accountListViewed(accounts: IPoc[], searchValue: string): void {
    const accountListViewedData: AccountListViewed =
      SegmentFormatData.formatAccountListSegmentData(accounts, searchValue);
    segmentAnalytics.accountListViewed(accountListViewedData);
  }

  static accountListSearched(searchValue: string): void {
    const accountListSearchedData: AccountListSearched =
      SegmentFormatData.formatAccountListSearchedSegmentData(searchValue);
    segmentAnalytics.accountListSearched(accountListSearchedData);
  }

  static wholesalerFiltered(vendorCountry: string): void {
    const wholesalerFilteredData: WholesalerFiltered =
      SegmentFormatData.formatWholesalerFilteredData(vendorCountry);
    segmentAnalytics.wholesalerFiltered(wholesalerFilteredData);
  }

  static accountClicked(pocsList: IPoc[], accountId: string): void {
    segmentAnalytics.accountClicked({
      screen_name: ScreenName.Accounts,
      listed_items: pocsList.length,
      clicked_account_position: pocsList
        .map((poc) => poc.accountId)
        .indexOf(accountId),
    });
  }

  static recentActivitiesViewed(): void {
    segmentAnalytics.recentActivitiesViewed({
      screen_name: ScreenName.AccountDetails,
    });
  }

  static recentActivitiesTabSelected(tabName: TabName): void {
    segmentAnalytics.recentActivitiesSelected({
      screen_name: ScreenName.AccountDetails,
      tab_name: tabName,
    });
  }
}

import React from "react";
import { UpdateAccountLog } from "services";
import { ActionOwnerInfo } from "../actionOwnerInfo";
import { AuditLogsItem } from "../base";
import {
  BINDING_OPTIONS,
  BINDING_OPTION_TO_ITEM,
} from "./UpdateAccountItem.consts";

type UpdateAccountActions =
  (typeof BINDING_OPTIONS)[keyof typeof BINDING_OPTIONS];

// @TODO - Enable wasApprovedBySystem flag in the sprint 120

export const UpdateAccountItem = ({
  updateAccountLog,
}: {
  updateAccountLog: UpdateAccountLog;
}): JSX.Element => {
  const {
    creationDate,
    doneBy,
    action: { metadata },
  } = updateAccountLog;
  const { actionOwnerName, userId } = metadata;

  return (
    <>
      {(Object.keys(userId) as unknown as UpdateAccountActions[])
        // .filter(() => metadata.wasApprovedBySystem === true)
        .map((action) => (
          <AuditLogsItem
            key={`${creationDate}-${action}`}
            type={BINDING_OPTION_TO_ITEM[action]}
            header={`User ${
              action === BINDING_OPTIONS.ADD ? "associated" : "disassociated"
            }`}
            body={userId[action]?.join(", ")}
            footer={
              <>
                <ActionOwnerInfo
                  creationDate={creationDate}
                  actionOwnerName={`Requested by ${actionOwnerName}`}
                  actionOwnerId={doneBy.userId}
                />
                <ActionOwnerInfo
                  creationDate={creationDate}
                  actionOwnerName="Approved by System"
                  actionOwnerId={doneBy.userId}
                />
              </>
            }
          />
        ))
        .flat()}
    </>
  );
};

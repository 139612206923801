import React from "react";
import { Breadcrumb as BaseBreadcrumb } from "@hexa-ui/components";

interface BreadcrumbLinkParams {
  url: string;
  label: string;
  onClick?: () => void;
  currentPage?: boolean;
}
export const BreadcrumbLink = ({
  url,
  label,
  onClick,
  currentPage,
}: BreadcrumbLinkParams): JSX.Element => {
  const { Item } = BaseBreadcrumb;
  return (
    <Item key={url} asChild isCurrentPage={currentPage}>
      <a href={url} onClick={() => onClick?.()}>
        {label}
      </a>
    </Item>
  );
};

/* eslint-disable no-nested-ternary */
import styled from "styled-components";

interface IProps {
  activeStatus: string;
}

export const Status = styled.div<IProps>`
  background: ${(props) =>
    props.activeStatus === "PENDING"
      ? "#625094"
      : props.activeStatus === "BLOCKED"
      ? "repeating-linear-gradient( 135deg, #C9201D30, #C9201D30 2.2px, #C9201Daa 2px, #C9201Daa 5px);"
      : "#ffffff00"};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 28px;
  margin-top: -25px;
  z-index: 999999;
  position: inherit;
`;

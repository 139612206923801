import { useContext } from "react";
import { DialogContext } from "../context/DialogContext";
import { DialogContextData } from "../types";

export const useDialog = (): DialogContextData => {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error("useDialog must not be used without DialogProvider");
  }

  return context;
};

import { SupportedCountryKeys } from "country-utils";

const applications = {
  adminportal: "Bees Hub",
  cmsadmin: "Bees CMS",
  deliveradmin: "Bees Deliver",
  force: "Force BDR",
  oncustomer: "Bees Grow",
  social: "Bees Social",
  supplier: "Bees Partner",
} as const;

type ApplicationKeys = keyof typeof applications | "b2b";

export interface IDecodedToken {
  appName: ApplicationKeys;
  userId: string;
  country: SupportedCountryKeys;
  name: string;
  givenName: string;
  email: string;
  scopes: string[];
  supportedCountries: SupportedCountryKeys[];
  preferredLanguage: string;
  vendorId?: string;
}

export function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function getToken(): string {
  const token = localStorage.getItem("authHeader");
  return token as string;
}

export function getDecodedToken(): IDecodedToken {
  const token = getToken().split(" ")[1];
  const {
    app,
    sub,
    country,
    name,
    email,
    scopes,
    supportedCountries,
    preferredLanguage,
    vendorId,
    ...rest
  } = parseJwt(token);
  return {
    appName: app,
    userId: sub,
    country,
    name,
    givenName: rest.given_name,
    email,
    scopes,
    supportedCountries,
    preferredLanguage,
    vendorId,
  };
}

export function getCountry(): string {
  const decodedToken: { country: string } = getDecodedToken();
  const { country } = decodedToken;
  return country;
}

export function getCountries(): string[] {
  const decodedToken: { country: string; supportedCountries: string[] } =
    getDecodedToken();
  const { country, supportedCountries } = decodedToken;
  let myCountries: string[] = [country, ...supportedCountries];
  myCountries = [...new Set(myCountries)];
  return myCountries;
}

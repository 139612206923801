import { Logo } from "pages/Pocs/Components/EmptySpace/Logo/Logo";
import React from "react";
import { StyledContainer, StyledSubText, StyledText } from "./styles";

export interface IMessage {
  message: string | JSX.Element;
  subMessage?: string;
}

export function Message({ message, subMessage }: IMessage): JSX.Element {
  return (
    <StyledContainer>
      <Logo />
      <StyledText>{message}</StyledText>
      {subMessage && <StyledSubText>{subMessage}</StyledSubText>}
    </StyledContainer>
  );
}

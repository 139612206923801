import { Box, Checkbox, TableHead, TableRow } from "@material-ui/core";
import { StyledAvatar } from "components/Avatar/styles";
import { InfiniteScrollLoading } from "components/InifiniteScrollLoading/InfiniteScrollLoading";
import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableRow,
} from "components/Table/styles";
import { initialLetters } from "helpers";
import { IPoc, ISearchPocResponse } from "interfaces";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PocService } from "services";
import { ISearchPocsParams } from "services/poc/PocService";
import { SegmentService } from "services/segmentService/segmentService";
import { scrollBottomListenerCallback } from "utils";
import { PocListSkeleton } from "../PocListSkeleton/PocListSkeleton";
import {
  StyledMessageNoAccounts,
  StyledPocContainer,
  StyledTableContainerHeader,
} from "./styles";

interface IProp {
  vendorId: string;
  country: string;
  searchValue: string;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
}

const fetchPocs = async (params: ISearchPocsParams) => {
  const pocService = new PocService();
  return pocService.searchPocs(params);
};

export const PocTable = ({
  searchValue,
  isLoading,
  setIsLoading,
  vendorId,
  country,
}: IProp): JSX.Element => {
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [loadingMorePocs, setLoadingMorePocs] = useState<boolean>(false);
  const [pocsList, setPocsList] = useState<IPoc[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize] = useState<number>(25);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [internalValue, setInternalValues] = useState<string>("");
  const [internalCountry, setInternalCountry] = useState<string>("");
  const [internalVendorId, setInternalVendorId] = useState<string>("");

  const handleRowClick = ({ customerAccountId, accountId, name }: IPoc) => {
    const searchParams = new URLSearchParams();
    searchParams.append("accountId", encodeURIComponent(accountId));
    searchParams.append("country", encodeURIComponent(country));
    searchParams.append(
      "customerAccountId",
      encodeURIComponent(customerAccountId)
    );
    searchParams.append("name", encodeURIComponent(name));
    SegmentService.accountClicked(pocsList, accountId);
    history.push({
      pathname: `/accounts/account-details-old?${searchParams.toString()}`,
    });
  };

  const onFetchPocsResponse = useCallback(
    (response: ISearchPocResponse) => {
      const {
        data: { accounts, pagination },
      } = response;
      const parsedAccount = accounts.map((poc: IPoc) => {
        return { ...poc, vendorCountry: country };
      });

      if (pagination.page === 0) setPocsList(parsedAccount);
      else setPocsList((prev) => [...prev, ...parsedAccount]);
      setTotalResults(pagination.totalResults);
      SegmentService.accountListViewed(accounts, searchValue);
      SegmentService.accountListSearched(searchValue);
      SegmentService.wholesalerFiltered(country);
    },
    [country]
  );

  useEffect(() => {
    setPage(0);
    setTotalResults(0);
    setPocsList([]);
    setInternalValues(searchValue);
    setInternalCountry(country);
    setInternalVendorId(vendorId);
  }, [searchValue, country, vendorId]);

  /**
   * When the internalValues and the actual values are different,
   * should not refetch the data from the above useEffect which resets
   * the page to 0.
   * This conditional logic triggers the refetch after the values' update
   */
  useEffect(() => {
    if (!internalVendorId || !internalCountry) return;
    if (
      internalCountry !== country ||
      internalValue !== searchValue ||
      internalVendorId !== vendorId
    )
      return;
    const params: ISearchPocsParams = {
      country: internalCountry,
      vendorId: internalVendorId,
      page,
      pageSize,
      value: internalValue,
    };
    if (page > 0) setLoadingMorePocs(true);
    else setIsLoading(true);
    fetchPocs(params)
      .then(onFetchPocsResponse)
      .finally(() => {
        if (page > 0) setLoadingMorePocs(false);
        else setIsLoading(false);
      });
  }, [
    country,
    internalCountry,
    internalValue,
    internalVendorId,
    onFetchPocsResponse,
    page,
    pageSize,
    searchValue,
    setIsLoading,
    vendorId,
  ]);

  const handlePocListScrollBottom = async () => {
    if (pocsList.length > 0 && pocsList.length < totalResults) {
      setPage(page + 1);
    }
  };

  const handleCheckboxClick = async (
    accountId: string,
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.stopPropagation();
    const isRowAlreadySelected = selectedRows.includes(accountId);
    if (!isRowAlreadySelected) setSelectedRows((prev) => [...prev, accountId]);
    else setSelectedRows((prev) => prev.filter((id) => id !== accountId));
  };

  return (
    <StyledPocContainer>
      <StyledTableContainerHeader>
        {pocsList.length > 0 &&
          `Showing ${pocsList.length} ${
            pocsList.length > 1 ? "results" : "result"
          }`}
      </StyledTableContainerHeader>
      {pocsList.length === 0 && !isLoading && (
        <StyledMessageNoAccounts>
          There are no accounts to show here
        </StyledMessageNoAccounts>
      )}
      {(pocsList.length > 0 || isLoading) && (
        <>
          <StyledTableContainer
            data-testid="pocs-table-container"
            onScroll={(e) =>
              scrollBottomListenerCallback(
                e.currentTarget,
                handlePocListScrollBottom
              )
            }
          >
            <StyledTable size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell align="left" width="300px">
                    Name
                  </StyledTableCell>
                  <StyledTableCell align="left">Account ID</StyledTableCell>
                  <StyledTableCell align="left">Users</StyledTableCell>
                  <StyledTableCell align="left">
                    Purchase Experience
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <PocListSkeleton />
              ) : (
                <StyledTableBody>
                  {pocsList.map((poc) => (
                    <StyledTableRow
                      key={poc?.accountId}
                      data-id={poc?.accountId}
                      data-vendor={poc?.accountId}
                      $isActive={selectedRows.includes(poc?.accountId)}
                      onClick={() => handleRowClick(poc)}
                      data-testid={poc.accountId}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          size="small"
                          data-checkbox
                          id={poc?.accountId}
                          data-testid={`poc-checkbox-${poc?.accountId}`}
                          checked={selectedRows.includes(poc.accountId)}
                          onClick={(e) => handleCheckboxClick(poc.accountId, e)}
                        />
                        <StyledAvatar data-avatar>
                          {initialLetters(poc?.name)}
                        </StyledAvatar>{" "}
                      </StyledTableCell>
                      <StyledTableCell align="left" className="font-bold">
                        <Box component="span">{poc?.name}</Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">{`${poc?.customerAccountId}`}</StyledTableCell>
                      <StyledTableCell align="left">
                        {`${poc?.usersCount || 0}`}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {`${poc?.isKeyAccount ? "BEES Link" : "BEES Customer"}`}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </StyledTableBody>
              )}
            </StyledTable>
          </StyledTableContainer>
          <InfiniteScrollLoading
            isVisible={loadingMorePocs}
            loadingMessage="Loading more accounts..."
          />
        </>
      )}
    </StyledPocContainer>
  );
};

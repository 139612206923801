export enum AprovalRequestStatusEnum {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
}

export enum AprovalRequestOperationEnum {
  UPDATE = "UPDATE",
  INSERT = "INSERT",
  DELETE = "DELETE",
  VIEW = "VIEW",
}
export interface IApprovalFlowRequesters {
  id: string;
  serviceName: string;
  operation: AprovalRequestOperationEnum;
  entity: string;
  title: string;
  country: string;
  user: {
    id: string;
    name: string;
    email: string;
  };
  scopesToView: string[];
  scopesToApproveRequest: string[];
  vendorId: string;
  createdAt: string;
  updatedAt: string;
  status: AprovalRequestStatusEnum;
  replier: string;
  rejectReason: string;
}

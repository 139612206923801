import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReportOutlinedIcon from "@material-ui/icons/ReportOutlined";
import React from "react";
import { ISnackBarProps, StyledAlert, StyledSnackbar } from "./styles";

export const SnackBar = ({
  severity,
  onClose,
  autoHideDuration,
  message,
  ...otherProps
}: ISnackBarProps): JSX.Element => {
  const icons: any = {
    success: <InfoOutlinedIcon htmlColor="#007A78" />,
    info: <InfoOutlinedIcon />,
    warning: <InfoOutlinedIcon />,
    error: <ReportOutlinedIcon htmlColor="#CA201D" />,
  };
  return (
    <StyledSnackbar
      {...otherProps}
      onClose={onClose}
      autoHideDuration={autoHideDuration || 6000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <StyledAlert
        icon={icons[severity || "success"]}
        onClose={onClose as any}
        severity={severity}
      >
        {message}
      </StyledAlert>
    </StyledSnackbar>
  );
};

export function initialLetters(fullName: string): string {
  if (fullName) {
    const name = fullName.split(" ");
    let initial = name[0].charAt(0).toLocaleUpperCase();
    if (name.length > 1) {
      initial += name[1].charAt(0).toLocaleUpperCase();
    }
    return initial;
  }
  return "";
}

import { Radio } from "@material-ui/core";
import styled, { css } from "styled-components";

interface ILabel {
  active: boolean;
  details: boolean;
}

const styledLabel = {
  active: css`
    border: 1px solid #000;
  `,
  details: css`
    max-height: 244px;
    transition: max-height 0.3s linear;
  `,
};

export const SubTitle = styled.h3`
  font: 600 16px/24px "Work Sans", sans-serif;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 24px;
`;

export const Description = styled.p`
  font: 400 14px/20px "Work Sans", sans-serif;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 16px;
`;

export const Form = styled.form`
  display: flex;
  align-items: stretch;
`;

export const Label = styled.label<ILabel>`
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  max-height: 72px;
  overflow: hidden;
  display: grid;
  align-items: center;
  grid-template-columns: 20px 1fr;
  grid-template-rows: auto auto;

  &:first-child {
    margin-right: 8px;
  }

  &:last-child {
    margin-left: 8px;
  }

  & div {
    display: flex;
    align-items: center;
  }

  & ul {
    list-style: none;
    margin-left: 8px;

    & li:first-child {
      font: 400 16px/24px "Work Sans", sans-serif;
    }

    & li:last-child {
      font: 400 12px/18px "Work Sans", sans-serif;
      color: rgba(0, 0, 0, 0.55);
      letter-spacing: 0.03px;
    }
  }

  ${({ active }) => active && styledLabel.active}

  ${({ details }) => details && styledLabel.details}
`;

export const RadioButton = styled(Radio)``;

export const Question = styled.label`
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  font: 500 16px/24px "Work Sans", sans-serif;
  margin-top: 16px;

  & svg {
    color: #0363c4;
    margin-right: 10px;
  }
`;

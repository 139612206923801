/* eslint-disable class-methods-use-this */
import { useAuthenticationService as authService } from "admin-portal-shared-services";
import { axiosInstance } from "services/axiosInstance";
import { v4 as uuidv4 } from "uuid";
import { getToken } from "../../helpers/Token/token";
import { getApiHost } from "../host/HostService";
import {
  buildAuditLog,
  isUpdateAccountLog,
  isUpdateAccountSettingsLog,
  parseAuditLog,
} from "./helpers";
import {
  AuditLog,
  AuditLogParams,
  LogEntitiesAvailable,
  UpdateAccountLog,
  UpdateAccountSettingsLog,
} from "./types";

export class AuditLogsService {
  async createAuditLog(
    accountCountry: string,
    auditLogParams: AuditLogParams
  ): Promise<void> {
    const apiHost = getApiHost();
    const token = getToken();
    const headers = {
      requestTraceId: uuidv4(),
      Authorization: token,
      country: accountCountry,
    };
    const body = buildAuditLog(auditLogParams);

    return authService()
      .enhancedAxios(axiosInstance)
      .post(`${apiHost}/audit`, body, { headers });
  }

  async getAuditLogHistory(
    entityId: string
  ): Promise<UpdateAccountLog[] | UpdateAccountSettingsLog[] | undefined> {
    const apiHost = getApiHost();
    const token = getToken();
    const headers = { requestTraceId: uuidv4(), Authorization: token };
    const params = { entityId, entity: LogEntitiesAvailable.Accounts };

    try {
      const auditLogs = await authService()
        .enhancedAxios(axiosInstance)
        .get(`${apiHost}/audit`, { headers, params });
      return auditLogs.data?.data?.content
        ?.map((auditLog: AuditLog) => parseAuditLog(auditLog))
        ?.filter(
          (auditLog: AuditLog) =>
            isUpdateAccountLog(auditLog) || isUpdateAccountSettingsLog(auditLog)
        );
    } catch {
      return undefined;
    }
  }
}
export const auditLogsService = new AuditLogsService();

import styled from "styled-components";

export const StyledButton = styled.button`
  background-color: #000;
  padding: 8px 16px;
  border-radius: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  height: 32px;
`;

export const StyledButtonText = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-family: Work Sans;
  color: rgba(255, 255, 255, 0.95);
`;

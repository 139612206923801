import { useAuthenticationService as authService } from "admin-portal-shared-services";
import axios, { AxiosInstance } from "axios";
import { getToken } from "helpers";
import { getApiHost } from "services/host/HostService";
import { v4 } from "uuid";
import { IApprovalFlowRequesters } from "./types";

export class ApprovalFlowService {
  private axiosInstance: AxiosInstance | any;

  private BASE_URL = getApiHost();

  constructor() {
    this.axiosInstance = axios.create();
  }

  async getRequesters(entityId: string): Promise<IApprovalFlowRequesters[]> {
    const URL = `${this.BASE_URL}/approval-requests?searchValue=${entityId}`;

    const headers = {
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const { data } = await authService()
      .enhancedAxios(this.axiosInstance)
      .get(URL, { headers });

    return data?.content;
  }
}

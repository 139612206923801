import { useHasPermission } from "admin-portal-shared-services";
import { BEES_ACCOUNT_TYPE } from "pages/PocDetails/Modais/ModalAccountSettings/account_type";
import { EnumAccountType } from "pages/PocDetails/Modais/ModalAccountSettings/Interfaces";
import { ModalAccountSettings } from "pages/PocDetails/Modais/ModalAccountSettings/ModalAccountSettings";
import React, { useCallback } from "react";
import { SegmentService } from "services/segmentService/segmentService";
import { SettingsSkeleton } from "./Skeleton/Skeleton";
import * as Styled from "./styles";

interface ISettings {
  isKeyAccount: boolean | undefined;
  isLoadingKeyAccount: boolean;
  accountId: string;
  accountName: string | undefined;
  associatedUsersCount: number;
  country: string;
  adminPortalHandlesIsKeyAccount: boolean;
  isLoadingHandleKeyAccount: boolean;
  reloadSetting: () => void;
}

export function Settings({
  isKeyAccount,
  isLoadingKeyAccount,
  accountId,
  accountName,
  associatedUsersCount,
  country,
  adminPortalHandlesIsKeyAccount,
  isLoadingHandleKeyAccount,
  reloadSetting,
}: ISettings): JSX.Element {
  const purchaseExperienceLabel = isKeyAccount
    ? BEES_ACCOUNT_TYPE.LINK.TITLE
    : BEES_ACCOUNT_TYPE.CUSTOMER.TITLE;
  const accountType = isKeyAccount
    ? EnumAccountType.BEES_LINK
    : EnumAccountType.BEES_CUSTOMER;
  const [openModalAccountSettings, setOpenModalAccountSettings] =
    React.useState<boolean>(false);
  const canChangeAccountSettings =
    useHasPermission("IdentityFE.POC.Write") && adminPortalHandlesIsKeyAccount;

  const openModal = useCallback(() => {
    setOpenModalAccountSettings(true);
    SegmentService.changeSettingStarted();
  }, []);

  const closeModal = useCallback(async () => {
    setOpenModalAccountSettings(false);
  }, []);

  return (
    <>
      {isLoadingKeyAccount && <SettingsSkeleton />}

      {!isLoadingKeyAccount && (
        <Styled.Container>
          <Styled.HeaderContainer>
            <h2>Settings</h2>

            {!isLoadingHandleKeyAccount && canChangeAccountSettings && (
              <button
                type="button"
                onClick={openModal}
                data-testid="open-modal-btn"
              >
                Change settings
              </button>
            )}
          </Styled.HeaderContainer>

          <Styled.ContentInfo>
            <h4>Purchase experience</h4>
            <span role="complementary">{purchaseExperienceLabel}</span>
          </Styled.ContentInfo>
        </Styled.Container>
      )}
      <ModalAccountSettings
        country={country}
        associatedUsersCount={associatedUsersCount}
        accountId={accountId}
        accountName={accountName}
        accountType={accountType}
        openModal={openModalAccountSettings}
        handleCloseModal={closeModal}
        handleCallbackUpdateSettings={reloadSetting}
      />
    </>
  );
}

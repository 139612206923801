import { Tooltip } from "@hexa-ui/components";
import { Copy as CopyIcon } from "@hexa-ui/icons";
import React from "react";
import { Container, StyledSvgContainer } from "./CopyToClipboard.styles";

interface IProp {
  textToCopy: string;
  label?: string;
}

export function CopyToClipboard({ textToCopy, label }: IProp): JSX.Element {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(String(textToCopy));
  };

  return (
    <Container>
      <Tooltip placement="right" text={label || textToCopy}>
        <StyledSvgContainer
          role="button"
          onClick={copyToClipboard}
          tabIndex={0}
        >
          <CopyIcon size="medium" />
        </StyledSvgContainer>
      </Tooltip>
    </Container>
  );
}

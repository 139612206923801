import React, { MouseEventHandler } from "react";
import * as Styled from "./Styles";

type Props = {
  text: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const AddButton = ({ text, onClick }: Props): JSX.Element => {
  return (
    <Styled.StyledButton onClick={onClick}>
      <Styled.StyledButtonText>{text}</Styled.StyledButtonText>
    </Styled.StyledButton>
  );
};

import React from "react";
import { UpdateAccountSettingsLog } from "services";
import { ActionOwnerInfo } from "../actionOwnerInfo";
import { AuditLogsItem, AuditLogsItemTypes } from "../base";

// @TODO - Enable wasApprovedBySystem flag in the sprint 120

export const UpdateAccountSettingsItem = ({
  updateAccountSettingsLog,
}: {
  updateAccountSettingsLog: UpdateAccountSettingsLog;
}): JSX.Element => {
  const {
    creationDate,
    doneBy,
    action: { metadata },
  } = updateAccountSettingsLog;
  const {
    actionOwnerName,
    previousPurchaseExperience,
    currentPurchaseExperience,
    // wasApprovedBySystem,
  } = metadata;

  return (
    <>
      {/* {wasApprovedBySystem === true && ( */}
      <AuditLogsItem
        key={`${creationDate}-${previousPurchaseExperience}`}
        type={AuditLogsItemTypes.Update}
        header="Account settings change"
        body={`Account purchase experience changed from ${previousPurchaseExperience} to ${currentPurchaseExperience}`}
        footer={
          <>
            <ActionOwnerInfo
              creationDate={creationDate}
              actionOwnerName={`Requested by ${actionOwnerName}`}
              actionOwnerId={doneBy.userId}
            />

            <ActionOwnerInfo
              creationDate={creationDate}
              actionOwnerName="Approved by System"
              actionOwnerId={doneBy.userId}
            />
          </>
        }
      />
      {/* )} */}
    </>
  );
};

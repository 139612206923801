import styled, { css } from "styled-components";

interface ILabel {
  active: boolean;
  details: boolean;
  disabled: boolean;
}

const styledLabel = {
  active: css`
    border: 1px solid #000;
  `,
  details: css`
    max-height: 244px;
    transition: max-height 0.3s linear;
  `,
  disabled: css`
    border-color: rgba(0, 0, 0, 0.16);
    pointer-events: none;
  `,
};

export const SelectBoxContainer = styled.label<ILabel>`
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  max-height: 72px;
  overflow: hidden;

  &:first-child {
    margin-right: 8px;
  }

  &:last-child {
    margin-left: 8px;
  }

  ${({ active }) => active && styledLabel.active}

  ${({ details }) => details && styledLabel.details}

  ${({ disabled }) => disabled && styledLabel.disabled}
`;

export const SelectBoxLabel = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-left: 8px;

    & h4 {
      font: 400 16px/24px "Work Sans", sans-serif;
    }

    & p {
      font: 400 12px/18px "Work Sans", sans-serif;
      color: rgba(0, 0, 0, 0.55);
      letter-spacing: 0.03px;
    }
  }
`;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin-left: 32px;
  margin-top: 24px;
`;

export const AccountAvatar = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 16px;
`;

export const AccountInfo = styled.div`
  margin-left: 16px;

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-top: 6px;
  }

  p {
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.55);
    margin-top: 4px;
  }
`;

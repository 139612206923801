import { AlertOctagon } from "@admin-portal-shared-components/icons";
import React, { FC } from "react";
import { Container, Message } from "./styles";

export const ErrorMessage: FC = () => (
  <Container>
    <AlertOctagon size="large" />
    <Message>
      Operation failed. The account settings couldn’t be saved. Please, try
      again in a few minutes.
    </Message>
  </Container>
);

import { styled } from "@bees/vision-tokens";

export const DialogHeaderWrapper = styled("div", {
  display: "flex",
  alignItems: "baseline",
  "> *:not(:last-child)": { marginRight: "$2" },
});

export const DialogContentWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  height: "70vh",
  "> *:not(:last-child)": { marginBottom: "$4" },
});

import { styled } from "@bees/vision-tokens";

export const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "max-content",
  height: "1.5rem",
  fontSize: "1rem",
  color: "$neutral90",
  lineHeight: "1.5rem",
  letterSpacing: "0.006rem",
});

export const StyledSvgContainer = styled("div", {
  cursor: "pointer",
  color: "$semanticInfoBasis",
});

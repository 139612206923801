import { Tooltip } from "@hexa-ui/components";
import { Info } from "@material-ui/icons";
import React from "react";
import { HintTooltipPlacement } from "./Hint.types";

export const Hint = ({
  content,
  placement = HintTooltipPlacement.right,
}: {
  content: string;
  placement?: HintTooltipPlacement;
}) => (
  <Tooltip placement={placement} text={content}>
    <Info fontSize="small" color="action" />
  </Tooltip>
);

import styled from "styled-components";

export const StyledAppName = styled.p`
  margin-bottom: 32px;
  color: rgba(0, 0, 0, 0.55);
  font: 400 16px/24px "Work Sans", sans-serif;
  width: 100%;
`;

export const CardContainer = styled.div`
  background-color: #fff;
  width: 100%;
  border-radius: 32px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
  height: calc(100vh - 250px);
`;

export const InnerCardContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-left: 16px;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
`;

import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import * as Styled from "../styles";

export const SettingsSkeleton = () => (
  <Styled.Container data-testid="skeleton list">
    <Styled.HeaderContainer>
      <Skeleton variant="text" width={100} height={30} />
      <Skeleton variant="text" width={150} height={40} />
    </Styled.HeaderContainer>

    <Styled.ContentInfo>
      <Skeleton variant="text" width={160} height={20} />
      <Skeleton variant="text" width={160} height={25} />
    </Styled.ContentInfo>
  </Styled.Container>
);

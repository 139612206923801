import { HelpCircle } from "@admin-portal-shared-components/icons";
import { useEnvService, useHasPermission } from "admin-portal-shared-services";
import { SnackBar } from "components/Snackbar/Snackbar";
import { getDecodedToken } from "helpers/Token/token";
import { Env } from "interfaces/IEnv";
import React, { FC } from "react";
import {
  AccountPurchaseExperience,
  LogEntitiesAvailable,
  LogOperationsAvailable,
  auditLogsService,
} from "services";
import { AccountService } from "services/Account/AccountService";
import { IKeyAccountProps } from "services/Account/interfaces";
import { SegmentService } from "services/segmentService/segmentService";
import { Modal } from "../../../../components/Modal/Modal";
import * as Styled from "../../../../components/Modal/styles";
import { Attributions } from "./Attributions/Attributions";
import { ErrorMessage } from "./ErrorMessage/ErrorMessage";
import { EnumAccountType, IModalAccountSettings } from "./Interfaces";
import { SelectBox, SelectBoxLabel } from "./SelectBox/SelectBox";
import { BEES_ACCOUNT_TYPE } from "./account_type";
import * as StyledAccount from "./styles";

export const ModalAccountSettings: FC<IModalAccountSettings> = ({
  accountId,
  accountName,
  associatedUsersCount,
  country,
  accountType,
  openModal,
  handleCloseModal,
  handleCallbackUpdateSettings,
}) => {
  const [selectedAccountType, setSelectedAccountType] =
    React.useState(accountType);
  const [selectedOption, setSelectedOption] = React.useState<string>(
    EnumAccountType.BEES_CUSTOMER
  );
  const [moreDetails, setMoreDetails] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const isBeesCustomerOptionSelected =
    selectedAccountType === EnumAccountType.BEES_CUSTOMER;
  const isBeesLinkOptionSelected =
    selectedAccountType === EnumAccountType.BEES_LINK;
  const [disableButtonSaveChange, setDisableButtonSaveChange] =
    React.useState(true);
  const canInviteUser = useHasPermission("IdentityFE.CustomerUser.Invite");
  const envService = useEnvService();
  const canChangeAccountSettings =
    useHasPermission("IdentityFE.POC.Write") && Boolean(selectedOption);
  const { name: loggedUserName } = getDecodedToken();

  const handleValidateDisableSaveChangeButton = () => {
    const isOptionSelected = accountType !== selectedAccountType;

    return isOptionSelected
      ? setDisableButtonSaveChange(true)
      : setDisableButtonSaveChange(false);
  };

  const handleChange = (event: React.BaseSyntheticEvent) => {
    const optionValue = event.target.value;
    const optionSelected = {
      BEES_LINK: EnumAccountType.BEES_LINK,
      BEES_CUSTOMER: "",
    };

    handleValidateDisableSaveChangeButton();
    setSelectedAccountType(optionValue);
    setSelectedOption(optionSelected[optionValue]);
  };

  const handleSaveChanges = async () => {
    const accountService = new AccountService();
    setIsLoading(true);

    const params: IKeyAccountProps = {
      country,
      accountId,
      isKeyAccount: Boolean(selectedOption),
    };
    SegmentService.changeSettingsSubmitted({
      accountId,
      name: accountName,
      associated_users: associatedUsersCount,
      isKeyAccount: params.isKeyAccount,
      canChangeAccountSettings,
      canInviteUser,
    });
    try {
      await accountService.changeKeyAccount(params);
      handleCloseModal();
      handleCallbackUpdateSettings();
      handleValidateDisableSaveChangeButton();
      setSuccessSnackbar(true);
      setHasError(false);
      SegmentService.changeSettingsSubmissionResult({
        accountId,
        name: accountName,
        associated_users: associatedUsersCount,
        isKeyAccount: params.isKeyAccount,
        canChangeAccountSettings,
        canInviteUser,
        purchase_experience_changed: true,
      });

      return await auditLogsService.createAuditLog(country, {
        operation: LogOperationsAvailable.Update,
        entity: LogEntitiesAvailable.Accounts,
        entityId: accountId,
        metadata: {
          wasApprovedBySystem: envService.getEnv() !== Env.PROD,
          actionOwnerName: loggedUserName,
          previousPurchaseExperience: AccountPurchaseExperience[accountType],
          currentPurchaseExperience:
            AccountPurchaseExperience[selectedAccountType],
        },
      });
    } catch (error) {
      setHasError(true);

      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const handlebtnCloseModal = () => {
    handleCloseModal();
    SegmentService.changeSettingCanceled();
  };
  return (
    <>
      <Modal
        modalTitle="Account Settings"
        isModalOpen={openModal}
        handleButtonCloseModal={handlebtnCloseModal}
      >
        <StyledAccount.SubTitle>Purchase experience</StyledAccount.SubTitle>
        <StyledAccount.Description>
          Applies to all customer users associated.
        </StyledAccount.Description>

        <StyledAccount.Form id="account-settings-form">
          <SelectBox
            active={isBeesCustomerOptionSelected}
            details={moreDetails}
            disabled={isLoading}
            id="bees-customer"
          >
            <SelectBoxLabel
              title={BEES_ACCOUNT_TYPE.CUSTOMER.TITLE}
              description={BEES_ACCOUNT_TYPE.CUSTOMER.DESCRIPTION}
              checked={isBeesCustomerOptionSelected}
              disabled={isLoading}
              onChange={handleChange}
              data-testid="bees customer"
              inputProps={{
                "aria-label": "bees-customer",
                id: "bees-customer",
                value: EnumAccountType.BEES_CUSTOMER,
                name: "accountType",
              }}
            />

            <Attributions
              attributions={BEES_ACCOUNT_TYPE.CUSTOMER.ATTRIBUTIONS}
            />
          </SelectBox>

          <SelectBox
            active={isBeesLinkOptionSelected}
            details={moreDetails}
            disabled={isLoading}
            id="bees-link"
          >
            <SelectBoxLabel
              title={BEES_ACCOUNT_TYPE.LINK.TITLE}
              description={BEES_ACCOUNT_TYPE.LINK.DESCRIPTION}
              onChange={handleChange}
              disabled={isLoading}
              checked={isBeesLinkOptionSelected}
              data-testid="bees link"
              inputProps={{
                "aria-label": "bees-link",
                id: "bees-link",
                value: EnumAccountType.BEES_LINK,
                name: "accountType",
              }}
            />

            <Attributions attributions={BEES_ACCOUNT_TYPE.LINK.ATTRIBUTIONS} />
          </SelectBox>
        </StyledAccount.Form>

        {!moreDetails && (
          <StyledAccount.Question onClick={() => setMoreDetails(true)}>
            <HelpCircle size="medium" />
            What is the difference?
          </StyledAccount.Question>
        )}

        {hasError && <ErrorMessage />}

        <Styled.ModalFooter style={{ marginTop: "32px" }}>
          <Styled.ModalButton
            variant="outline"
            onClick={handlebtnCloseModal}
            disabled={isLoading}
          >
            Cancel
          </Styled.ModalButton>
          <Styled.ModalButton
            onClick={handleSaveChanges}
            disabled={isLoading || disableButtonSaveChange}
            form="account-settings-form"
          >
            {isLoading && "Saving..."}
            {!isLoading && "Save changes"}
          </Styled.ModalButton>
        </Styled.ModalFooter>
      </Modal>
      <SnackBar
        open={successSnackbar}
        onClose={() => setSuccessSnackbar(false)}
        message="Account settings successfully changed."
        severity="success"
      />
    </>
  );
};

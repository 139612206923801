import { CopyToClipboard } from "components/CopyToClipboard";
import { format, parseISO } from "date-fns";
import React from "react";
import { ActionOwnerInfoWrapper } from "./ActionOwnerInfo.styles";
import { ActionOwnerInfoParams } from "./ActionOwnerInfo.types";

export const ActionOwnerInfo = ({
  creationDate,
  actionOwnerName,
  actionOwnerId,
}: ActionOwnerInfoParams): JSX.Element => (
  <ActionOwnerInfoWrapper>
    {`${actionOwnerName} on ${format(
      parseISO(creationDate),
      "MMMM dd, HH:mm aaa"
    )}`}
    <CopyToClipboard textToCopy={actionOwnerId} label="Copy user ID" />
  </ActionOwnerInfoWrapper>
);

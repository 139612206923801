import { Button, Paragraph } from "@hexa-ui/components";
import { Clock } from "@hexa-ui/icons";
import { useDialog } from "components/Dialog";
import { DialogHeader } from "components/Dialog/base";
import { Hint } from "components/Hint/Hint";
import React from "react";
import { SegmentService } from "services/segmentService/segmentService";
import {
  DialogContentWrapper,
  DialogHeaderWrapper,
} from "./AuditLogsHistory.styles";
import { AuditLogsList } from "./list";

export const AuditLogsHistory = ({
  targetEntityId,
}: {
  targetEntityId: string;
}): JSX.Element => {
  const { openDialog } = useDialog();
  const onOpenRecentActivityList = () => {
    openDialog(
      <DialogContentWrapper>
        <Paragraph size="basis">
          The audit log saves information for a period of 30 days.
        </Paragraph>
        <AuditLogsList targetEntityId={targetEntityId} />
      </DialogContentWrapper>,
      {
        title: (
          <DialogHeaderWrapper>
            <DialogHeader title="Recent activity" />
            <Hint content="To access all activities, go to BEES Audit logs" />
          </DialogHeaderWrapper>
        ),
      }
    );
    SegmentService.recentActivitiesViewed();
  };

  return (
    <Button
      variant="secondary"
      size="medium"
      icon={Clock}
      onClick={onOpenRecentActivityList}
      leading
    >
      Recent activity
    </Button>
  );
};

import { Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { ISelectBox, ISelectBoxLabel } from "../Interfaces";
import * as Styled from "./styles";

const RadioButton = withStyles({
  root: {
    padding: 0,
    color: "#000",
    "&$checked": {
      color: "#000",
    },
  },
  checked: {},
  disabled: {
    color: "rgba(0,0,0,0.16)",
    "&$checked": {
      color: "rgba(0,0,0,0.16)",
    },
  },
})((props) => <Radio color="default" {...props} />);

export const SelectBox: FC<ISelectBox> = ({
  id,
  active,
  details,
  disabled,
  children,
}) => {
  return (
    <Styled.SelectBoxContainer
      disabled={disabled}
      htmlFor={id}
      active={active}
      details={details}
      data-testid="select box"
    >
      {children}
    </Styled.SelectBoxContainer>
  );
};

export const SelectBoxLabel: FC<ISelectBoxLabel> = ({
  title,
  description,
  ...rest
}) => {
  return (
    <Styled.SelectBoxLabel>
      <RadioButton {...rest} />

      <div>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </Styled.SelectBoxLabel>
  );
};

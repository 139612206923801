import { Heading } from "@hexa-ui/components";
import React from "react";

export enum DialogHeadingSizes {
  Hero = "Hero",
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
  H4 = "H4",
  H5 = "H5",
}

export interface DialogHeaderProps {
  title: string;
  size?: DialogHeadingSizes;
}

export const DialogHeader = ({
  title,
  size = DialogHeadingSizes.H2,
}: DialogHeaderProps) => <Heading size={size}>{title}</Heading>;

import React, { ReactNode } from "react";
import { TabContent, TabItem, TabsContainer, TabsList } from "./Tabs.styles";
import { ItemProps } from "@hexa-ui/components";

export type ITab = {
  name: string;
  content: ReactNode;
  onClick?: () => void;
};

interface ITabProps extends ItemProps {
  tabsList: ITab[];
  tabSelected?: string;
}

export const Tabs = ({ tabSelected, tabsList }: ITabProps): JSX.Element => {
  return (
    <TabsContainer
      defaultValue={tabsList[0]?.name}
      value={tabSelected}
      data-testid="tabs"
    >
      <TabsList>
        {tabsList.map((tab) => (
          <TabItem key={tab.name} value={tab.name} onClick={tab.onClick}>
            {tab.name}
          </TabItem>
        ))}
      </TabsList>
      {tabsList.map((tab) => (
        <TabContent key={tab.name} value={tab.name}>
          {tab.content}
        </TabContent>
      ))}
    </TabsContainer>
  );
};

import styled from "styled-components";

export const Container = styled.button`
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;

  svg {
    pointer-events: none;
  }
`;

import { Breadcrumb as BaseBreadcrumb } from "@hexa-ui/components";
import React from "react";

export interface IBreadcrumbParams {
  homeLink: string;
  children: React.ReactNode;
  clearStorage?: () => void;
}

export const Breadcrumb = ({
  homeLink,
  children,
  clearStorage,
}: IBreadcrumbParams): JSX.Element => {
  const { Root, HomeItem } = BaseBreadcrumb;

  return (
    <Root>
      <HomeItem asChild ariaLabel="home-item">
        <a onClick={clearStorage} href={homeLink}>
          Home
        </a>
      </HomeItem>
      {children}
    </Root>
  );
};

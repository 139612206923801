import AccountAvatarImage from "assets/accountAvatar.png";
import React from "react";
import { AccountAvatar, AccountInfo, Container } from "./syles";

interface IProps {
  name: string;
  customerAccountId: string;
}

export function PocListInfo({ name, customerAccountId }: IProps): JSX.Element {
  return (
    <Container>
      <AccountAvatar src={AccountAvatarImage} />

      <AccountInfo>
        <h3>{name}</h3>
        <p>Account ID #{customerAccountId}</p>
      </AccountInfo>
    </Container>
  );
}

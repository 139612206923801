import { Dialog } from "components/Dialog/Dialog";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { DialogProps, OpenDialogParams } from "../types";
import { DialogContext } from "./DialogContext";

export const DialogProvider = ({
  children,
}: {
  children?: ReactNode;
}): JSX.Element => {
  const [dialogOptions, setDialogOptions] = useState<DialogProps>({
    open: false,
  });

  const closeDialog = useCallback(() => setDialogOptions({ open: false }), []);

  const openDialog = useCallback(
    (dialogChildren: ReactNode, options?: Partial<OpenDialogParams>) =>
      setDialogOptions({
        children: dialogChildren,
        ...(options || {}),
        open: true,
      }),
    []
  );

  const providedValue = useMemo(
    () => ({ openDialog, closeDialog }),
    [openDialog, closeDialog]
  );

  return (
    <DialogContext.Provider value={providedValue}>
      <Dialog
        {...dialogOptions}
        onClose={(e) => {
          dialogOptions.onClose?.(e);
          closeDialog();
        }}
      />
      {children}
    </DialogContext.Provider>
  );
};

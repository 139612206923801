/* eslint-disable class-methods-use-this */
import { useAuthenticationService as authService } from "admin-portal-shared-services";
import axios, { AxiosPromise } from "axios";
import { v4 } from "uuid";
import { getToken } from "../../helpers/Token/token";
import { getApiHost } from "../host/HostService";
import {
  IAccount,
  IAxiosResponse,
  IKeyAccountProps,
  IKeyAccountResponse,
} from "./interfaces";

export class AccountService {
  async getAccountDetails(
    country: string,
    accountId: string
  ): Promise<IAxiosResponse<IAccount>> {
    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const params = {
      accountId,
    };

    const { data } = await authService()
      .enhancedAxios(axios.create())
      .get(`${getApiHost()}/user-mfe/accounts/details`, { headers, params });
    return data;
  }

  async changeKeyAccount({
    country,
    accountId,
    isKeyAccount,
  }: IKeyAccountProps): Promise<AxiosPromise<IKeyAccountResponse>> {
    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const DTO = {
      accountId,
      isKeyAccount,
    };

    const configs = {
      headers,
    };

    const { data } = await authService()
      .enhancedAxios(axios.create())
      .post(`${getApiHost()}/poc-mfe/accounts/key-account`, DTO, configs);

    return data;
  }
}

export enum EnumAccountType {
  BEES_CUSTOMER = "BEES_CUSTOMER",
  BEES_LINK = "BEES_LINK",
}

export interface IModalAccountSettings {
  country: string;
  accountId: string;
  accountName: string | undefined;
  associatedUsersCount: number;
  accountType: EnumAccountType;
  openModal: boolean;
  handleCloseModal: () => void;
  handleCallbackUpdateSettings: () => void;
}

export interface IAttributions {
  attributions: string[];
}

export interface ISelectBox {
  id: string;
  active: boolean;
  details: boolean;
  disabled: boolean;
}

export interface ISelectBoxLabel {
  title: string;
  description: string;
}

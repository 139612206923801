import { TableCell, TableContainer } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import styled, { css } from "styled-components";

const alternativeElement = css`
  & [data-avatar] {
    display: none;
  }

  & [data-checkbox] {
    display: block;
  }
`;

const highlightRow = css`
  background-color: #f0ecfc;
`;

export const StyledTableContainer = styled(TableContainer)`
  max-height: calc(100vh - 340px);
`;

export const StyledTable = styled(Table)`
  max-width: 100%;
  th {
    height: 36px;
    background-color: #f2f2f2;
    font: 600 16px/20px "Barlow";
    letter-spacing: normal;
    padding: 0 16px;

    &:first-child {
      text-align: center;
      padding: 0 28px;
    }

    & [data-checkbox] {
      svg {
        fill: #212121;
        color: #212121;
      }
    }
  }

  & tr:not(thead tr) {
    height: 64px;
  }

  & tr.active {
    ${alternativeElement}
    background-color: #F0ECFC;
  }
`;

export const StyledTableRow = styled(TableRow)<{ $isActive: boolean }>`
  height: 64px;

  &:last-child {
    & td {
      border-bottom: 0;
    }
  }

  & [data-checkbox] {
    display: none;

    &:active,
    svg {
      fill: #212121;
      color: #212121;
    }
  }

  &:hover {
    ${alternativeElement}
    background-color: #e4e4e4;
    cursor: pointer;
  }

  ${({ $isActive }: { $isActive: boolean }) => {
    if ($isActive) return alternativeElement.concat(highlightRow);
    return null;
  }}
`;

export const StyledTableCell = styled(TableCell)`
  font: 400 14px/20px "Work Sans";
  letter-spacing: 0.1px;

  &:first-child {
    padding: 0 28px;
  }

  &.font-bold {
    font: 600 14px/20px "Work Sans";
    text-transform: capitalize;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const StyledTableBody = styled(TableBody)`
  position: relative;
  overflow: hidden;
  border-radius: 0 0 32px 32px;
`;

import { styled } from "@bees/vision-tokens";
import { Heading as HexaHeading } from "@hexa-ui/components";

export const ContainerOptions = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$4",
});

export const Section = styled("section", {
  marginBottom: "$8",
});

export const Heading = styled(HexaHeading, {
  "&&": {
    marginBottom: "$4",
  }
});
import { Snackbar } from "@material-ui/core";
import styled, { css } from "styled-components";

interface CustomSnackbarProps {
  variant: "success" | "error";
}

export const StyledSnackbar = styled(Snackbar)<CustomSnackbarProps>`
  position: relative;
  ${({ variant }) => {
    const backgroundColorMap = {
      success: "#d1f7f6",
      error: "#FFDEDE",
    };
    return css`
      background-color: ${backgroundColorMap[variant]};
      border-radius: 8px;

      svg {
        margin-right: 5px;
      }
    `;
  }}
`;

export const StyledMessageContainer = styled.div`
  padding: 10px 16px;
  display: flex;
  align-items: center;
`;

export const StyledFeedbacksContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 15px;
  top: 80px;
  margin: 0 auto;
  z-index: 99999;
`;

import { Button } from "@hexa-ui/components";
import React from "react";
import * as Styled from "./ProcessingFileModal.styles";

interface IProcessingFileModalProps {
  onClose: () => void;
}

export const ProcessingFileModal = ({
  onClose,
}: IProcessingFileModalProps): JSX.Element => (
  <>
    <Styled.Heading size="H2">Processing file</Styled.Heading>
    <Styled.Paragraph>
      The file was sent to the Processing files tab and may take a few minutes
      to be processed. You can close this window now.
    </Styled.Paragraph>
    <Styled.ContainerActions>
      <Button variant="secondary" size="large" onClick={onClose}>
        Close
      </Button>
    </Styled.ContainerActions>
  </>
);
import { TableBody } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import styled from "styled-components";
import * as Styled from "../../../../components";

const StyledSkeleton = styled(Skeleton)`
  border-radius: 12px;
  height: 30px;
  max-width: 200px;
`;

export function PocListSkeleton(): JSX.Element {
  const listSize = [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <TableBody data-testid="skeleton list">
      {listSize.map((item) => (
        <Styled.StyledTableRow
          $isActive={false}
          key={item}
          data-id={item}
          data-testid={item}
        >
          <Styled.StyledTableCell />
          <Styled.StyledTableCell align="left">
            <StyledSkeleton />
          </Styled.StyledTableCell>
          <Styled.StyledTableCell align="left">
            <StyledSkeleton />
          </Styled.StyledTableCell>
          <Styled.StyledTableCell align="left">
            <StyledSkeleton />
          </Styled.StyledTableCell>
        </Styled.StyledTableRow>
      ))}
    </TableBody>
  );
}

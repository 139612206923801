import { SkeletonLoader } from "@hexa-ui/components";
import React from "react";
import { LoaderWrapper } from "./AuditLogsListLoader.styles";

export const AuditLogsListLoader = (): JSX.Element => {
  const skeletonItems = Array.from({ length: 8 });

  return (
    <LoaderWrapper data-testid="audit-logs-history-loader">
      {skeletonItems.map((_, index) => (
        <SkeletonLoader
          key={index}
          height="4rem"
          width={`${Math.floor(Math.random() * (100 - 50 + 1) + 50)}%`}
        />
      ))}
    </LoaderWrapper>
  );
};

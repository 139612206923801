import { IFeedbacksFunctions } from "components/Feedbacks/Feedbacks";

export interface IExportUserListModalProps {
  modalProps: {
    isOpenModal: boolean;
    setOpenModal: (value: boolean) => void;
    vendorId: string;
    vendorName?: string;
    country: string;
    feedbackRef: React.MutableRefObject<IFeedbacksFunctions | undefined>;
  };
}

export interface IOption {
  id: string;
  label: string;
  value: string;
  checked?: boolean;
}

export interface ICheckboxProps {
  title: string;
  options: IOption[];
}

export const userOptions: IOption[] = [
  { id: "user-active", label: "Active", value: "ACTIVE", checked: true },
  { id: "user-pending", label: "Pending", value: "PENDING", checked: true },
  { id: "user-blocked", label: "Blocked", value: "BLOCKED", checked: true },
];

export const purchaseOptions: IOption[] = [
  { id: "bees-link", label: "Bees Link", value: "BEES LINK", checked: true },
  {
    id: "bees-customer",
    label: "Bees Customer",
    value: "BEES CUSTOMER",
    checked: true,
  },
];

import React, { ReactNode } from "react";
import {
  ContentLine,
  ItemContent,
  ItemWrapper,
  TypeIndicator,
} from "./AuditLogsItem.styles";
import { AuditLogsItemTypes } from "./types";

interface AuditLogsItemProps {
  type: AuditLogsItemTypes;
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
}

export const AuditLogsItem = ({
  type,
  header,
  body,
  footer,
}: AuditLogsItemProps): JSX.Element => (
  <ItemWrapper>
    <TypeIndicator type={type} />
    <ItemContent>
      {header && <ContentLine type="primary">{header}</ContentLine>}
      {body && <ContentLine type="secondary">{body}</ContentLine>}
      {footer && <ContentLine type="terciary">{footer}</ContentLine>}
    </ItemContent>
  </ItemWrapper>
);

import React, { createContext, useState, useContext } from "react";

export type TabContextProps = {
  tabName: string;
  setTabName: (value: string) => void;
};

const TabContext = createContext<TabContextProps | undefined>(undefined);

export const useTab = () => {
  const context = useContext(TabContext);
  if (context === undefined) {
    throw new Error("useTab must be used within a TabProvider");

  }
  return context;
}

export const TabProvider = ({ children }) => {
  const [tabName, setTabName] = useState("Tab");

  return (
    <TabContext.Provider value={{ tabName, setTabName }}>
      {children}
    </TabContext.Provider>
  );
};
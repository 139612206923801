export const BEES_ACCOUNT_TYPE = {
  CUSTOMER: {
    TITLE: "BEES Customer",
    DESCRIPTION: "Traditional buyers",
    ATTRIBUTIONS: [
      "Manual products selecting",
      "Low purchasing volume",
      "For non-chain-belonging customers",
    ],
  },
  LINK: {
    TITLE: "BEES Link",
    DESCRIPTION: "Automated buyers",
    ATTRIBUTIONS: [
      "Purchase order files uploading",
      "High purchasing volume",
      "For chain customers or accounts with EDI ordering processes",
    ],
  },
};

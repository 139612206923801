import { styled } from "@bees/vision-tokens";

export const AuditLogsListContainer = styled("div", {
  width: "100%",
  "> *:not(:last-child)": { marginBottom: "$4" },
});

export const CenteringEmptyState = styled("div", {
  display: "flex",
  justifyContent: "center",
});

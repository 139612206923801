import {
  Heading as HexaHeading,
  Paragraph as HexaParagraph,
} from "@hexa-ui/components";

import { styled } from "@bees/vision-tokens";

export const Heading = styled(HexaHeading, {
  "&&": {
    marginBottom: "$4",
  }
});

export const Paragraph = styled(HexaParagraph, {
  "&&": {
    marginBottom: "$8",
  }
});

export const ContainerActions = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "$4",
});
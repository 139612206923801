import { useHasPermission } from "admin-portal-shared-services";
import { Feedbacks } from "components/Feedbacks/Feedbacks";
import { IAccount } from "interfaces/IAccount";
import queryString from "query-string";
import React, { EffectCallback, useCallback, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AccountService } from "services/Account/AccountService";
import { SegmentService } from "services/segmentService/segmentService";
import { VendorService } from "../../services/vendor/VendorService";
import { BffService } from "../../services/bff-service";
import {
  AccountDetailsSkeleton,
  AuditLogsHistory,
  BackLink,
  PocListInfo,
  PocListUsers,
  Settings,
} from "./Components";
import {
  CardContainer,
  Header,
  HeaderSection,
  InnerCardContainer,
  StyledAppName,
} from "./styles";

export function PocDetailsPage(): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);
  const accountCountry = params.country as string;
  const accountId = params.accountId as string;

  const [isLoading, setIsLoading] = React.useState(false);
  const [handlesIsKeyAccount, setHandlesIsKeyAccount] = React.useState(false);
  const [vendorName, setVendorName] = React.useState<string>("");
  const [usersList, setUsersList] = React.useState([]);
  const [isLoadingKeyAccount, setIsLoadingKeyAccount] =
    React.useState<boolean>(true);
  const [account, setAccount] = React.useState<IAccount | undefined>();
  const feedbackRef = useRef();
  const canInviteUser = useHasPermission("IdentityFE.CustomerUser.Invite");
  const canChangeAccountSettings =
    useHasPermission("IdentityFE.POC.Write") && handlesIsKeyAccount;

  const fetchUsers: EffectCallback = () => {
    if (
      !account?.vendorAccountId ||
      !account?.vendorId ||
      !accountCountry ||
      !accountId
    )
      return;

    const bffService = new BffService();

    setIsLoading(true);
    bffService
      .getUsersByAccount({
        country: accountCountry,
        legacyAccountId: accountId,
        vendorAccountId: account?.vendorAccountId ?? "",
        vendorId: account?.vendorId ?? "",
      })
      .then((result) => {
        setUsersList(result);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  React.useEffect(fetchUsers, [
    accountCountry,
    accountId,
    account?.vendorAccountId,
    account?.vendorId,
  ]);
  const [isLoadingHandleKeyAccount, setIsLoadingHandleKeyAccount] =
    React.useState<boolean>(true);

  const getAccountDetails = useCallback(async () => {
    setIsLoadingKeyAccount(true);
    const accountDetailsService = new AccountService();

    try {
      const { data } = await accountDetailsService.getAccountDetails(
        accountCountry,
        accountId
      );
      setAccount(data);
    } catch (err: any) {
      const {
        response: { status },
      } = err;

      if (status) {
        history.push(`/error/${status}`);
      } else {
        history.push("/error/400");
      }
    } finally {
      setIsLoadingKeyAccount(false);
    }
  }, [accountId, accountCountry]);

  useEffect(() => {
    const getHandleKeyAccount = async () => {
      setIsLoadingHandleKeyAccount(true);
      const vendorService = new VendorService();
      const vendor = await vendorService.getVendorOfAccount(
        accountCountry,
        accountId
      );

      const { adminPortalHandlesIsKeyAccount, vendorName: name } = vendor;
      if (name) {
        setVendorName(name);
      }
      setHandlesIsKeyAccount(adminPortalHandlesIsKeyAccount);
      setIsLoadingHandleKeyAccount(false);
    };

    getAccountDetails();
    getHandleKeyAccount();
  }, [accountId, accountCountry]);

  useEffect(() => {
    if (!isLoading && account) {
      SegmentService.accountDetailsViewed({
        ...account,
        associated_users: usersList?.length,
        canChangeAccountSettings,
        canInviteUser,
      });
    }
  }, [isLoading, account]);

  return (
    <div className="container_mfe" data-testid="location-display">
      <StyledAppName>BEES User Management</StyledAppName>
      <Header>
        <HeaderSection>
          <BackLink onClick={() => history.goBack()} />
          <h3>Account details</h3>
        </HeaderSection>

        {account && <AuditLogsHistory targetEntityId={account?.accountId} />}
      </Header>

      {!isLoading && usersList && (
        <CardContainer>
          <InnerCardContainer>
            <PocListInfo
              name={account?.name || "-"}
              customerAccountId={account?.customerAccountId || "-"}
            />
            <Settings
              isKeyAccount={account?.isKeyAccount}
              isLoadingKeyAccount={isLoadingKeyAccount}
              country={accountCountry}
              accountId={accountId}
              accountName={account?.name}
              associatedUsersCount={usersList?.length}
              adminPortalHandlesIsKeyAccount={handlesIsKeyAccount}
              isLoadingHandleKeyAccount={isLoadingHandleKeyAccount}
              reloadSetting={getAccountDetails}
            />

            <PocListUsers
              vendorAccountId={account?.vendorAccountId || ""}
              vendorId={account?.vendorId || ""}
              usersList={usersList}
              isLoading={isLoading}
              account={account}
              onCompleteDisassociateAction={fetchUsers}
              feedbackRef={feedbackRef}
              accountId={accountId}
              pocName={account?.name || ""}
              customerAccountId={account?.customerAccountId || ""}
              country={accountCountry}
              vendorName={vendorName}
            />
          </InnerCardContainer>
        </CardContainer>
      )}
      {isLoading && <AccountDetailsSkeleton />}
      <Feedbacks ref={feedbackRef} />
    </div>
  );
}

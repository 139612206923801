import {
  FormControlLabel,
  Radio,
  RadioGroup,
  SvgIcon,
} from "@material-ui/core";
import { IVendor } from "interfaces/IWholesaler";
import React from "react";
import * as Styled from "./styles";

interface Props {
  selectedVendorId: string;
  vendors: IVendor[];
  onHandleChange: (vendor: IVendor) => void;
  isLoading: boolean;
}

export function VendorsFilter({
  selectedVendorId,
  vendors,
  onHandleChange,
  isLoading,
}: Props): JSX.Element {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const vendor: IVendor | any = vendors.find(
      (vendor) => vendor.vendorId === event.target.value
    );
    onHandleChange(vendor);
  };

  return (
    <Styled.StyledFilterSessionContainer>
      <Styled.StyledFilterSessionHeader>
        Wholesaler/Branch
      </Styled.StyledFilterSessionHeader>
      <Styled.StyledSessionOptionsContainer>
        <RadioGroup
          name="countries"
          value={selectedVendorId}
          onChange={handleChange}
          data-testid="select-vendor"
        >
          {vendors?.map((vendor: IVendor) => {
            return (
              <FormControlLabel
                value={vendor.vendorId}
                control={
                  <Radio
                    style={{ color: "black", fontWeight: 50 }}
                    disabled={isLoading}
                    checkedIcon={
                      <SvgIcon>
                        <ellipse
                          strokeWidth="1.2px"
                          ry="10"
                          rx="10"
                          cy="11"
                          cx="11"
                          stroke="#000"
                          fill="#fff"
                        />
                        <ellipse
                          strokeWidth="1px"
                          ry="3.125"
                          rx="3.125"
                          cy="11"
                          cx="11"
                          stroke="#000"
                          fill="#000"
                        />
                      </SvgIcon>
                    }
                    icon={
                      <SvgIcon>
                        <ellipse
                          strokeWidth="1.2px"
                          ry="10"
                          rx="10"
                          cy="11"
                          cx="11"
                          stroke="#000"
                          fill="#fff"
                        />
                      </SvgIcon>
                    }
                    data-testid={`radio-${vendor.vendorId}`}
                  />
                }
                label={vendor.displayName || vendor.legalName}
                key={vendor.vendorId}
              />
            );
          })}
        </RadioGroup>
      </Styled.StyledSessionOptionsContainer>
    </Styled.StyledFilterSessionContainer>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  border-radius: 8px;
  background-color: #ffdede;
  color: rgb(201, 32, 29);
  padding: 14px 16px;
  display: flex;
  margin-top: 24px;
`;

export const Message = styled.p`
  color: rgba(0, 0, 0, 0.9);
  font: 400 14px/20px "Work Sans", sans-serif;
  margin-left: 8px;
`;

import { useEnvService, useHasPermission } from "admin-portal-shared-services";
import { Modal } from "components/Modal/Modal";
import * as StyledModal from "components/Modal/styles";
import { SegmentFormatData } from "helpers";
import { getDecodedToken } from "helpers/Token/token";
import { IUser } from "interfaces";
import { IAccount } from "interfaces/IAccount";
import { Env } from "interfaces/IEnv";
import React, { useEffect } from "react";
import {
  LogEntitiesAvailable,
  LogOperationsAvailable,
  UserService,
  auditLogsService,
} from "services";
import { IAccountSegmentDataParams } from "services/segmentService/helpers/segmentFormatData";
import { SegmentService } from "services/segmentService/segmentService";
import * as Styled from "./Styles";

type Props = {
  usersCount: number;
  usersSelected: IUser[] | [];
  account: IAccount | undefined;
  onComplete: (result: PromiseSettledResult<any>[]) => void;
};

type IDialogTexts = {
  title: string;
  body: string;
  confirmButton: string;
};

interface IPayload {
  userId: string;
  value: string;
}

export const DisassociateButton = ({
  usersSelected,
  usersCount,
  onComplete,
  account,
}: Props): JSX.Element => {
  const canInviteUser = useHasPermission("IdentityFE.CustomerUser.Invite");
  const hasIdentityFeUserWrite = useHasPermission("IdentityFE.User.Write");
  const hasIdentityUserWrite = useHasPermission("Identity.User.Write");
  const envService = useEnvService();
  const canWriteUser = hasIdentityFeUserWrite && hasIdentityUserWrite;
  const canWritePOCUser = useHasPermission("IdentityFE.User.Write-POC");
  const canChangeAccountSettings = canWriteUser || canWritePOCUser;
  const userService = new UserService();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [dialogTexts, setDialogTexts] = React.useState<IDialogTexts>();
  const { name: loggedUserName } = getDecodedToken();

  useEffect(() => {
    if (usersSelected.length > 1)
      setDialogTexts({
        title: "Remove these users?",
        body: "The selected users won’t be able to buy for this account anymore.",
        confirmButton: `Remove (${usersSelected.length})`,
      });
    else
      setDialogTexts({
        title: "Remove this user?",
        body: "The user won’t be able to buy for this account anymore.",
        confirmButton: `Remove`,
      });
  }, [usersSelected]);

  const openModalRemoveUsers = () => {
    setIsOpen(true);
    SegmentService.userRemovalStarted();
  };

  const closeModalRemoveUsers = (isClickCancelButton: boolean) => {
    setIsOpen(false);
    SegmentService.userRemovalCanceled({ isClickCancelButton });
  };

  const convertUsersAndAccountToPayload = (): IPayload[] => {
    if (account && usersSelected) {
      return usersSelected.map((user) => ({
        userId: user.id,
        value: account?.accountId,
        vendorAccountId: account?.vendorAccountId,
        vendorId: account?.vendorId,
      }));
    }
    return [];
  };

  const callPromisses = (payloads: IPayload[]): Promise<any>[] => {
    if (account && account.country) {
      return payloads.map(async (payload) => {
        await userService.disassociateUser(account.country, payload);

        await auditLogsService.createAuditLog(account.country, {
          operation: LogOperationsAvailable.Update,
          entity: LogEntitiesAvailable.Accounts,
          entityId: account.accountId,
          metadata: {
            wasApprovedBySystem: envService.getEnv() !== Env.PROD,
            actionOwnerName: loggedUserName,
            userId: { deleted: [payload.userId] },
          },
        });

        await auditLogsService.createAuditLog(account.country, {
          operation: LogOperationsAvailable.Update,
          entity: LogEntitiesAvailable.Users,
          entityId: payload.userId,
          metadata: {
            wasApprovedBySystem: envService.getEnv() !== Env.PROD,
            actionOwnerName: loggedUserName,
            accountId: { deleted: [account.accountId] },
          },
        });
      });
    }
    return [];
  };

  const disassociate = async (): Promise<any> => {
    const accountSegmentDataParams: IAccountSegmentDataParams = {
      ...account,
      canChangeAccountSettings,
      canInviteUser,
      associated_users: usersCount,
    };
    SegmentService.userRemovalSubmitted(accountSegmentDataParams!);

    const payloads = convertUsersAndAccountToPayload();
    const promises = callPromisses(payloads);
    const results = await Promise.allSettled(promises);

    const { remomal_success_count, removal_fail_count } =
      SegmentFormatData.getRemovalCount(results);
    SegmentService.userRemovalSubmissionResult({
      ...accountSegmentDataParams,
      associated_users: usersCount - remomal_success_count,
      remomal_success_count,
      removal_fail_count,
    });
    onComplete(results);
  };

  return (
    <>
      {canChangeAccountSettings && usersSelected.length > 0 && (
        <Styled.StyledButton
          onClick={openModalRemoveUsers}
          data-testid="disassociate-button"
          name="disassociate"
        >
          <Styled.StyledButtonText>Remove</Styled.StyledButtonText>
        </Styled.StyledButton>
      )}

      <Modal
        modalTitle={dialogTexts?.title || ""}
        data-testid="disassociate-modal"
        isModalOpen={isOpen}
        handleButtonCloseModal={() => closeModalRemoveUsers(false)}
      >
        <StyledModal.ModalSubtitle>
          {dialogTexts?.body}
        </StyledModal.ModalSubtitle>
        <StyledModal.ModalFooter>
          <StyledModal.ModalButton
            onClick={() => closeModalRemoveUsers(true)}
            data-testid="cancel-button"
            variant="outline"
          >
            Cancel
          </StyledModal.ModalButton>
          <StyledModal.ModalButton
            onClick={disassociate}
            data-testid="confirm-button"
          >
            {dialogTexts?.confirmButton}
          </StyledModal.ModalButton>
        </StyledModal.ModalFooter>
      </Modal>
    </>
  );
};

import { Skeleton } from "@material-ui/lab";
import React from "react";
import * as Styled from "./styles";
import { StyledContainer } from "./styles";

export function AccountDetailsSkeleton(): JSX.Element {
  return (
    <StyledContainer data-testid="account-deatails-page-skeleton">
      <Styled.StyledRowSkeletonContainer>
        <Skeleton width="72px" height="72px" variant="circle" />
        <Styled.StyledTitleAndSubtitleContainer>
          <Skeleton height="32px" width="318px" />
          <Skeleton height="20px" width="170px" style={{ marginTop: "4px" }} />
        </Styled.StyledTitleAndSubtitleContainer>
      </Styled.StyledRowSkeletonContainer>
      <div style={{ marginBottom: "36px" }}>
        <Styled.StyledTitleAndButtonContainer>
          <Skeleton height="32px" width="110px" />
          <Skeleton height="40px" width="180px" />
        </Styled.StyledTitleAndButtonContainer>
        <Skeleton height="20px" width="170px" />
        <Skeleton height="24px" width="120px" />
      </div>
      <div>
        <div style={{ display: "flex" }}>
          <Skeleton height="32px" width="150px" />
          <Skeleton
            height="24px"
            width="24px"
            variant="circle"
            style={{ marginLeft: "4px" }}
          />
        </div>
        <Styled.StyledTitleAndButtonContainer>
          <Skeleton height="32px" width="100px" />
          <Skeleton height="40px" width="60px" />
        </Styled.StyledTitleAndButtonContainer>
      </div>
      <Styled.StyledListOfUsersContainer>
        <Styled.StyledRowSkeletonContainer>
          <Skeleton width="48px" height="48px" variant="circle" />
          <Styled.StyledTitleAndSubtitleContainer>
            <Skeleton height="24px" width="218px" />
            <Skeleton
              height="16px"
              width="217px"
              style={{ marginTop: "4px" }}
            />
          </Styled.StyledTitleAndSubtitleContainer>
        </Styled.StyledRowSkeletonContainer>
        <Styled.StyledRowSkeletonContainer>
          <Skeleton width="48px" height="48px" variant="circle" />
          <Styled.StyledTitleAndSubtitleContainer>
            <Skeleton height="24px" width="250px" />
            <Skeleton
              height="16px"
              width="140px"
              style={{ marginTop: "4px" }}
            />
          </Styled.StyledTitleAndSubtitleContainer>
        </Styled.StyledRowSkeletonContainer>
        <Styled.StyledRowSkeletonContainer>
          <Skeleton width="48px" height="48px" variant="circle" />
          <Styled.StyledTitleAndSubtitleContainer>
            <Skeleton height="24px" width="180px" />
            <Skeleton
              height="16px"
              width="260px"
              style={{ marginTop: "4px" }}
            />
          </Styled.StyledTitleAndSubtitleContainer>
        </Styled.StyledRowSkeletonContainer>
        <Styled.StyledRowSkeletonContainer>
          <Skeleton width="48px" height="48px" variant="circle" />
          <Styled.StyledTitleAndSubtitleContainer>
            <Skeleton height="24px" width="250px" />
            <Skeleton
              height="16px"
              width="140px"
              style={{ marginTop: "4px" }}
            />
          </Styled.StyledTitleAndSubtitleContainer>
        </Styled.StyledRowSkeletonContainer>
        <Styled.StyledRowSkeletonContainer>
          <Skeleton width="48px" height="48px" variant="circle" />
          <Styled.StyledTitleAndSubtitleContainer>
            <Skeleton height="24px" width="180px" />
            <Skeleton
              height="16px"
              width="260px"
              style={{ marginTop: "4px" }}
            />
          </Styled.StyledTitleAndSubtitleContainer>
        </Styled.StyledRowSkeletonContainer>
        <Styled.StyledRowSkeletonContainer>
          <Skeleton width="48px" height="48px" variant="circle" />
          <Styled.StyledTitleAndSubtitleContainer>
            <Skeleton height="24px" width="250px" />
            <Skeleton
              height="16px"
              width="140px"
              style={{ marginTop: "4px" }}
            />
          </Styled.StyledTitleAndSubtitleContainer>
        </Styled.StyledRowSkeletonContainer>
      </Styled.StyledListOfUsersContainer>
    </StyledContainer>
  );
}

import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid #e0e0e0;
  overflow-y: overlay;
`;

export const SearchImg = styled.img`
  justify-content: center;
  width: 240px;
  margin: 10% auto 32px;
`;

export const StyledText = styled.div`
  margin-top: 12px;
  color: #000;
  font-family: "Barlow";
  font-size: 32px;
  text-align: center;
`;

export const StyledSubText = styled.div`
  margin-top: 18px;
  color: #707372;
  font-family: "Barlow";
  font-size: 14px;
  text-align: center;
`;

import { enUS } from "./languages/en-US";
import { es419 } from "./languages/es-419";
import { koKR } from "./languages/ko-KR";
import { ptBR } from "./languages/pt-BR";
import { LocaleMessagesMap, SupportedLocales } from "./types";

export const supportedLocales = ["en-US", "pt-BR", "es-419", "ko-KR"] as const;

export const DEFAULT_LANGUAGE = "en-US";

export const isSupportedLocale = (locale: string): locale is SupportedLocales =>
  supportedLocales.includes(locale as SupportedLocales);

export const messages: LocaleMessagesMap = {
  "en-US": enUS,
  "pt-BR": ptBR,
  "es-419": es419,
  "ko-KR": koKR,
};

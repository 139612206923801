import { QueryClient, QueryClientProvider } from "react-query";
import DesignTokensProvider from "@admin-portal-shared-components/design-tokens-provider";
import { createGenerateClassName, StylesProvider } from "@material-ui/core";
import { PropsPerClusterProvider } from "admin-portal-shared-services";
import { AppConfig } from "AppBarConfig";
import { GlobalStyle } from "global/global";
import React from "react";
import { IntlProvider } from "./i18n";

type AppProps = {
  segmentKey: Record<"default" | "Restricted_US", string>;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      refetchOnReconnect: false,
    },
  },
});

export function App(appProps: AppProps): JSX.Element {
  const generateClassName = createGenerateClassName({
    productionPrefix: "admin-portal-identity-poc-mfe",
    seed: "admin-portal-identity-poc-mfe",
  });

  return (
    <PropsPerClusterProvider propValues={appProps}>
      <QueryClientProvider client={queryClient}>
        <IntlProvider>
          <GlobalStyle />
          <DesignTokensProvider>
            <StylesProvider generateClassName={generateClassName}>
              <AppConfig />
            </StylesProvider>
          </DesignTokensProvider>
        </IntlProvider>
      </QueryClientProvider>
    </PropsPerClusterProvider>
  );
}

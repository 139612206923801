import { Box } from "@material-ui/core";
import styled from "styled-components";

export const StyledContainer = styled(Box)`
  background-color: white;
  border-radius: 32px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 1fr;
  height: calc(100vh - 250px);
  width: -webkit-fill-available;
  overflow-y: scroll;
  flex-direction: row;
  padding: 32px;
`;

export const StyledRowSkeletonContainer = styled.div`
  display: flex;
`;

export const StyledTitleAndSubtitleContainer = styled.div`
  margin-left: 16px;
  align-self: center;
`;

export const StyledTitleAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 4px;
`;

export const StyledListOfUsersContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

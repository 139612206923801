import { Check } from "@admin-portal-shared-components/icons";
import React, { FC } from "react";
import { IAttributions } from "../Interfaces";
import { List } from "./styles";

export const Attributions: FC<IAttributions> = ({ attributions }) => {
  return (
    <List data-testid="attribution list">
      {attributions.map((attribution) => (
        <li key={attribution}>
          <Check size="medium" /> {attribution}
        </li>
      ))}
    </List>
  );
};

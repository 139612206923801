import { Snackbar, SnackbarProps } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import styled from "styled-components";

export interface ISnackBarProps extends SnackbarProps {
  severity?: Color;
}

export const StyledSnackbar = styled(Snackbar)<ISnackBarProps>`
  & .admin-portal-identity-user-mfe-MuiAlert-root {
    color: rgba(0, 0, 0, 0.9);
    font-family: "Work Sans", sans-serif;
    height: 48px;
    width: 400px;
    border-radius: 8px;
  }
  & .admin-portal-identity-user-mfe-MuiAlert-standardSuccess {
    background-color: #d1f7f6;
  }
  & .admin-portal-identity-user-mfe-MuiAlert-standardError {
    background-color: #ffdede;
  }
`;

export const StyledAlert = styled(Alert)`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;
`;

import { useAuthenticationService as authService } from "admin-portal-shared-services";
import axios from "axios";
import { getToken } from "helpers";
import { VendorOfAccount } from "interfaces";
import { getApiHost } from "services/host/HostService";
import { v4 as uuidv4 } from "uuid";

export class VendorService {
  URL = `${getApiHost()}`;

  async getVendorOfAccount(
    accountCountry: string,
    accountId: string
  ): Promise<VendorOfAccount> {
    const headers = {
      country: accountCountry,
      requestTraceId: uuidv4(),
      Authorization: getToken(),
    };

    const params = {
      accountId,
    };

    const url = `${this.URL}/poc-mfe/accounts/vendor`;

    const { data } = await authService()
      .enhancedAxios(axios.create())
      .get(url, { params, headers });

    return data.data;
  }
}

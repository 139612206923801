import styled from "styled-components";

export const StyledAppName = styled.p`
  margin-bottom: 32px;
  color: rgba(0, 0, 0, 0.55);
  font: 400 16px/24px "Work Sans", sans-serif;
  width: 100%;
`;

export const StyledHeaderTitle = styled.h1`
  width: 100%;
  color: rgba(0, 0, 0, 0.9);
  font: 600 40px/80px "Barlow", sans-serif;
`;

export const StyledHeaderContainer = styled.div`
  margin: 0 auto;
  width: 100%;
`;
export const StyledHeaderActions = styled.div`
  width: 100%;
`;

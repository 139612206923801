import { useAuthenticationService as authService } from "admin-portal-shared-services";
import axios from "axios";
import { getToken } from "helpers";
import { IPoc, ISearchPocResponse } from "interfaces";
import { getApiHost } from "services/host/HostService";
import { v4 as uuidv4 } from "uuid";

export interface ISearchPocsParams {
  value: string;
  page: number;
  pageSize: number;
  vendorId: string;
  country: string;
}

export class PocService {
  env = localStorage.getItem("environment");

  URL = `${getApiHost()}`;

  async getPocs(userCountry: string, vendorId: string): Promise<IPoc[]> {
    const headers = {
      country: userCountry,
      requestTraceId: uuidv4(),
      Authorization: getToken(),
    };

    const params = {
      vendorId,
      page: 1,
      pageSize: 25,
    };

    const { data } = await authService()
      .enhancedAxios(axios.create())
      .get(`${this.URL}/pocs/list`, {
        params,
        headers,
      });
    return data;
  }

  async searchPocs(
    searchParams: ISearchPocsParams
  ): Promise<ISearchPocResponse> {
    const { value, page, pageSize, country, vendorId } = searchParams;

    const headers = {
      country,
      requestTraceId: uuidv4(),
      Authorization: getToken(),
    };

    const params = {
      vendorId,
      value,
      page,
      pageSize,
    };

    const { data } = await authService()
      .enhancedAxios(axios.create())
      .get(`${this.URL}/poc-mfe/vendors/accounts`, {
        params,
        headers,
      });
    return data;
  }
}

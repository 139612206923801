import { useHasPermission } from "admin-portal-shared-services";
import React, { lazy, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbLink,
} from "identity-admin-mfe/modules/shared/components/Breadcrumb";
import { Tabs } from "identity-admin-mfe/modules/shared/components/Tabs/Tabs";
import { PocList } from "./Components/PocList/PocList";
import { HeaderTitle, StyledScreen } from "./PocPage.styles";
import { useTab } from "../../hooks/useTab";

const ProcessedFilesPage = lazy(
  () =>
    import(
      "identity-admin-mfe/modules/account-management/pages/AccountsListPage/components/ProcessedFilesTab"
    )
);

export const PocPage = (): JSX.Element | null => {
  const isUs = window.location.host.includes("us");
  const hasPermissionExportAccounts = useHasPermission(
    "IdentityFE.ExportAccounts.Execute"
  );
  const canExportUserList = isUs && hasPermissionExportAccounts;
  const { tabName, setTabName } = useTab();

  useEffect(() => {
    setTabName("Account list");
  }, [setTabName]);

  const tabsList = (() => {
    const defaultTabs = [
      {
        name: "Account list",
        content: <PocList />,
        onClick: () => setTabName("Account list"),
      },
    ];

    if (canExportUserList) {
      defaultTabs.push({
        name: "Processed Files",
        content: <ProcessedFilesPage />,
        onClick: () => setTabName("Processed Files"),
      });
    }
    return defaultTabs;
  })();

  return (
    <StyledScreen className="container_mfe">
      <Breadcrumb homeLink="/accounts">
        <BreadcrumbLink url="#void" label="Accounts" currentPage />
      </Breadcrumb>

      <HeaderTitle>Accounts</HeaderTitle>
      <Tabs tabsList={tabsList} tabSelected={tabName} />
    </StyledScreen>
  );
};

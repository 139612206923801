import { AxiosResponse } from "axios";
import { AUDIT_LOGS_APP_ID } from "./consts";

export interface MetadataBase {
  wasApprovedBySystem: boolean;
  actionOwnerName?: string;
}
export interface UpdateAccountMetadata extends MetadataBase {
  userId: { added?: string[]; deleted?: string[] };
}

export interface UpdateAccountSettingsMetadata extends MetadataBase {
  previousPurchaseExperience: AccountPurchaseExperience;
  currentPurchaseExperience: AccountPurchaseExperience;
}

export interface UpdateUserMetadata extends MetadataBase {
  accountId: { added?: string[]; deleted?: string[] };
}

export interface AuditLog {
  creationDate: string;
  target: {
    entityId: string;
    entity: LogEntitiesAvailable;
  };
  action: {
    operation: LogOperationsAvailable;
    metadata: unknown;
  };
  doneBy: {
    appId: typeof AUDIT_LOGS_APP_ID;
    userId: string;
  };
}

export interface UpdateUserLog extends AuditLog {
  target: {
    entityId: string;
    entity: LogEntitiesAvailable.Users;
  };
  action: {
    operation: LogOperationsAvailable.Update;
    metadata: UpdateUserMetadata;
  };
}

export interface UpdateAccountLog extends AuditLog {
  target: {
    entityId: string;
    entity: LogEntitiesAvailable.Accounts;
  };
  action: {
    operation: LogOperationsAvailable.Update | LogOperationsAvailable.Delete;
    metadata: UpdateAccountMetadata;
  };
}

export interface UpdateAccountSettingsLog extends AuditLog {
  target: {
    entityId: string;
    entity: LogEntitiesAvailable.Accounts;
  };
  action: {
    operation: LogOperationsAvailable.Update;
    metadata: UpdateAccountSettingsMetadata;
  };
}

export interface AuditLogParams {
  operation: LogOperationsAvailable;
  entity: LogEntitiesAvailable;
  entityId: string;
  metadata: Record<string, unknown>;
}

export enum LogOperationsAvailable {
  Delete = "DELETE",
  Insert = "INSERT",
  Update = "UPDATE",
  View = "VIEW",
}

export enum LogEntitiesAvailable {
  Accounts = "ACCOUNTS",
  Users = "USERS",
}

export enum AccountPurchaseExperience {
  BEES_CUSTOMER = "Bees Customer",
  BEES_LINK = "Bees Link",
}

export interface AuditLogService {
  createAuditLog: (
    accountCountry: string,
    params: AuditLogParams
  ) => Promise<AxiosResponse>;
  getAuditLogHistory: (
    entityId: string
  ) => Promise<UpdateAccountLog[] | UpdateAccountSettingsLog[] | undefined>;
}

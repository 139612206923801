import { useHasPermission } from "admin-portal-shared-services";
import { InformationCard } from "components";
import { Filter } from "components/Filter/Filter";
import { SearchBar } from "components/Forms/SearchBar/SearchBar";
import { Message } from "components/Message/Message";
import { SnackBar } from "components/Snackbar/Snackbar";
import { useDebounce } from "hooks/useDebounce/useDebounce";
import { IVendor } from "interfaces/IWholesaler";
import queryString from "query-string";
import React, { ChangeEvent, FormEvent, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@hexa-ui/components";
import { UserService } from "services";
import { Download } from "@admin-portal-shared-components/icons";
import { useIntl } from "react-intl";
import { Feedbacks, IFeedbacksFunctions } from "components/Feedbacks/Feedbacks";
import { PocTable } from "../PocTable/PocTable";
import {
  Container,
  ContainerHeaderActions,
  MessageContainer,
  StyledContainer,
} from "./PocList.styles";
import { ExportUserListModal } from "../ExportUserListModal";

const onSubmit = (target: FormEvent) => {
  target.preventDefault();
};

export const PocList = (): JSX.Element | null => {
  const history = useHistory();
  const [searchPoc, setSearchPoc] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingVendors, setIsLoadingVendors] = React.useState(true);
  const [selectedVendor, setSelectedVendor] = useState<IVendor>();
  const debounceValue = useDebounce<string>(searchPoc);
  const [openExportUserModal, setOpenExportUserModal] =
    useState<boolean>(false);
  const hasIdentityFeUserWrite = useHasPermission("IdentityFE.User.Write");
  const hasIdentityUserWrite = useHasPermission("Identity.User.Write");
  const hasUserWritePermission = hasIdentityFeUserWrite && hasIdentityUserWrite;
  const hasUserReadPermission = useHasPermission("IdentityFE.User.Read");
  const hasCustomerUserReadPermission = useHasPermission(
    ["IdentityFE.CustomerUser.Read", "Identity.User.Read"],
    "AND"
  );
  const hasPOCReadPermission = useHasPermission("IdentityFE.POC.Read");
  const hasPermissionToAccessUser =
    hasUserReadPermission ||
    hasUserWritePermission ||
    hasCustomerUserReadPermission;

  const feedbackRef = useRef<IFeedbacksFunctions>();
  const hasPermissiontoAccess =
    hasUserReadPermission || hasUserWritePermission || hasPOCReadPermission;
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [openSnackBar, setOpenSnackBar] = React.useState<boolean>(false);
  const [vendors, setvendors] = React.useState<IVendor[]>([]);
  const { formatMessage } = useIntl();
  const isUs = window.location.host.includes("us");
  const hasPermissionExportAccounts = useHasPermission(
    "IdentityFE.ExportAccounts.Execute"
  );
  const canExportUserList = isUs && hasPermissionExportAccounts;

  const getVendors = async () => {
    try {
      setIsLoadingVendors(true);
      const userService = new UserService();
      const data: IVendor[] = await userService.getVendorsOfUser();
      if (Array.isArray(data)) {
        const verdors = data.filter(
          (vendor) => vendor.displayName || vendor.legalName
        );
        setvendors(verdors);
        const vendor = data.find(
          (vendor) => vendor.vendorId === params.wholesaler
        );
        if (vendor) {
          setSelectedVendor(vendor);
        } else {
          const defaultVendor = data[0];
          if (defaultVendor) {
            setSelectedVendor(defaultVendor);
            if (params.wholesaler) {
              setOpenSnackBar(true);
              setSearchPoc("");
            }
          }
        }
      }
    } finally {
      setIsLoadingVendors(false);
    }
  };

  React.useEffect(() => {
    getVendors();
  }, []);

  React.useEffect(() => {
    if (!hasPOCReadPermission && hasPermissionToAccessUser) {
      history.push("/users");
    } else if (!hasPermissiontoAccess) {
      history.push("/error/403");
    }

    if (params.search) setSearchPoc(String(params.search));
  }, [
    hasPOCReadPermission,
    hasPermissiontoAccess,
    hasPermissionToAccessUser,
    history,
  ]);

  React.useEffect(() => {
    history.replace({
      search: `?search=${debounceValue}&wholesaler=${
        selectedVendor?.vendorId || ""
      }`,
    });
  }, [debounceValue]);

  const handleChangeSearchPoc = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchPoc(event.target.value);
  };

  const handleSelectedVendor = (vendor: IVendor) => {
    setSelectedVendor(vendor);
    history.replace({
      search: `?search=${debounceValue}&wholesaler=${vendor.vendorId}`,
    });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackBar(false);
  };

  if (vendors.length === 0 && !isLoadingVendors) {
    return (
      <MessageContainer>
        <Message
          message={
            <span>
              No wholesaler registered <br /> to your account.
            </span>
          }
          subMessage="Please, get in touch with our support team."
        />
      </MessageContainer>
    );
  }

  return vendors.length > 0 ? (
    <Container>
      <ContainerHeaderActions>
        <SearchBar
          onChange={handleChangeSearchPoc}
          value={searchPoc}
          onSubmit={onSubmit}
          placeholder="Search by account ID or name"
          data-testid="search"
        />
        {canExportUserList ? (
          <Button
            onClick={() => setOpenExportUserModal(true)}
            leading
            size="medium"
            variant="primary"
            icon={() => <Download size="medium" />}
            data-testid="exportUserListButton"
          >
            {formatMessage({ id: "pocPage_exportUserList" })}
          </Button>
        ) : null}
      </ContainerHeaderActions>
      {selectedVendor?.country !== "US" && (
        <div style={{ marginTop: "24px", marginBottom: "24px" }}>
          <InformationCard message="The country/wholesaler selected displays only key accounts (BEES Link)" />
        </div>
      )}
      <StyledContainer>
        <Filter
          vendors={vendors}
          selectedVendorId={selectedVendor?.vendorId || ""}
          vendorChange={handleSelectedVendor}
          isLoading={isLoading}
        />
        <div data-testid="table-container">
          {selectedVendor && (
            <PocTable
              country={selectedVendor?.country}
              vendorId={selectedVendor?.vendorId}
              searchValue={debounceValue}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </div>
      </StyledContainer>
      <SnackBar
        open={openSnackBar}
        onClose={handleCloseSnackbar}
        message="You do not have access to the requested wholesaler. The filter will be reset"
        severity="warning"
      />
      <ExportUserListModal
        modalProps={{
          isOpenModal: openExportUserModal,
          setOpenModal: setOpenExportUserModal,
          feedbackRef,
          vendorId: selectedVendor?.vendorId ?? "",
          vendorName: selectedVendor?.displayName ?? "",
          country: selectedVendor?.country ?? "",
        }}
      />
      <Feedbacks ref={feedbackRef} />
    </Container>
  ) : null;
};

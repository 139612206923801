import styled from "styled-components";

export const StyledFilterSessionHeader = styled.h3`
  height: 19px;
  width: 276px;
  color: rgba(0, 0, 0, 0.9);
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
`;

export const StyledFilterSessionContainer = styled.div`
  margin: 16px;
  margin-bottom: 14px;
`;

export const StyledSessionOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;
